<template>
  <div :id="dropdownId" :class="`${parentStyling} ${widthFull ? '' : 'inline'}`">
    <DropdownAlign placement="bottom-left">
      <template #trigger>
        <div
          @click="toggle"
          :class="`cursor-pointer rounded px-4 py-2 hover:text-white ${inputClass} ${
            widthFull ? '' : 'inline bg-gray-900'
          }`"
        >
          <span class="text-gray-400" v-if="!justText">{{ name }}: </span>
          <span
            class="font-medium hover:text-white"
            :class="widthFull ? 'flex justify-between' : 'inline-flex items-center justify-center'"
            v-if="justText"
          >
            {{ name }} <IconChevronDown class="ml-0" />
          </span>
          <span
            v-if="selectedItem && !justText"
            :class="`pl-1 font-medium ${
              items && selectedItem && defaultIndex !== undefined && items[defaultIndex].id === selectedItem.id
                ? 'text-blue-300'
                : ''
            }`"
          >
            {{ selectedItem.name }}
          </span>
          <span v-if="!selectedItem && !justText" class="w-10 pl-1">—</span>
        </div>
      </template>

      <template #dropdown>
        <div>
          <DropdownBase
            v-if="droppedDown"
            :items="dropdownItems"
            :x-anchor="xAnchor"
            :truncate-text="truncateText"
            custom-dropdown-item-class="!text-xs"
            :style="[
              dropdownWidth ? `width: ${dropdownWidth} !important` : '',
              dropdownHeight ? `max-height: ${dropdownHeight} !important` : ''
            ]"
            :custom-classes="widthFull ? 'mt-1' : ''"
            :sort-options="sortOptions"
            :sort-key="sortKey"
            :sort-order="sortOrder"
            :class="`max-h-96 overflow-y-auto overflow-x-hidden ${dropdownClass}`"
            @clicked="handleClick"
          >
            <input
              :style="`width: ${dropdownWidth ? dropdownWidth : '3000px'}`"
              :id="searchId"
              v-model="searchTerm"
              :class="`sticky top-0 border-b border-grid bg-gray-900 px-4 py-2 outline-none`"
              placeholder="Search"
              @keydown="keydown"
            />

            <p v-if="dropdownItems.length === 0" class="whitespace-normal break-words p-5 text-center text-gray-400">
              Sorry, no results found
            </p>
          </DropdownBase>
        </div>
      </template>
    </DropdownAlign>
  </div>
</template>

<script setup>
import DropdownBase from './DropdownBase.vue';
import DropdownAlign from './DropdownAlign.vue';
import IconChevronDown from '../icon/IconChevronDown.vue';
import { ref, onMounted, onBeforeUnmount, computed, watch } from 'vue';
const emit = defineEmits(['selected', 'dropdownChange']);

const props = defineProps({
  items: Array,
  name: String,
  defaultIndex: Number,
  selectedItem: Object,
  dropdownClass: String,
  hideClear: Boolean,
  inputClass: String,
  justText: Boolean,
  widthClass: String,
  dropdownWidth: String,
  dropdownHeight: String,
  xAnchor: {
    type: String,
    default: 'right'
  },
  truncateText: {
    type: Boolean,
    default: false
  },
  sortOptions: { type: Boolean, default: false },
  sortKey: { type: String, default: 'label' },
  sortOrder: { type: String, default: 'asc' },
  widthFull: { type: Boolean, default: false },
  parentStyling: { type: String, default: 'text-sm' }
});

const searchTerm = ref('');
const dropdownId = ref(`dropdown_${Math.random()}`);
const searchId = ref(`search_${Math.random()}`);
const droppedDown = ref(false);
const selectedIndex = ref(undefined);

function keydown(e) {
  // escape
  if (e.keyCode === 27) {
    droppedDown.value = false;
    return;
  }

  // down arrow
  if (e.keyCode === 40) {
    e.preventDefault();
    selectedIndex.value = selectedIndex.value === undefined ? 0 : selectedIndex.value + 1;
    return;
  }

  // up arrow
  if (e.keyCode === 38) {
    e.preventDefault();
    selectedIndex.value = (selectedIndex.value || 0) - 1;

    if (selectedIndex.value < 0) {
      selectedIndex.value = 0;
    }

    return;
  }

  // enter key
  if (e.keyCode === 13 && selectedIndex.value !== undefined) {
    handleClick(dropdownItems.value[selectedIndex.value].id);
  }
}
function handleClick(action) {
  emit(
    'selected',
    action === 'clear'
      ? undefined
      : props.items.filter(item => {
          return item.id === action;
        })[0]
  );
  droppedDown.value = false;
}
function close(e) {
  const drop = document.getElementById(dropdownId.value);
  if (!drop.contains(e.target) && droppedDown.value && e.target.id !== dropdownId.value) {
    droppedDown.value = false;
    emit('dropdownChange', false);
  }
}
function toggle() {
  droppedDown.value = !droppedDown.value;
  if (droppedDown.value) {
    setTimeout(() => {
      const elm = document.getElementById(searchId.value);
      elm.focus();
    });
  }
}

const dropdownItems = computed(() => {
  const base =
    props.selectedItem && props.defaultIndex === undefined && !props.hideClear
      ? [
          {
            text: 'Clear',
            id: 'clear',
            class: 'text-sm text-red-200 cursor-pointer'
          }
        ]
      : [];

  const items = base.concat(
    props.items
      .filter(i => {
        return i.name.toLowerCase().includes(searchTerm.value.toLowerCase());
      })
      .map(i => {
        return {
          text: i.name,
          id: i.id,
          class: 'text-sm cursor-pointer'
        };
      })
  );

  return items.map((item, i) => {
    return {
      ...item,
      selected: i === selectedIndex.value
    };
  });
});
onMounted(() => {
  document.addEventListener('click', close);

  if (props.selectedItem === undefined && props.defaultIndex !== undefined) {
    selectedIndex.value = props.defaultIndex;
    emit('selected', props.items[selectedIndex.value]);
  }
});
onBeforeUnmount(() => {
  document.removeEventListener('click', close);
});

watch(
  () => props.items,
  () => {
    if (props.selectedItem === undefined && props.defaultIndex !== undefined) {
      selectedIndex.value = props.defaultIndex;
      emit('selected', props.items[selectedIndex.value]);
    }
  }
);
watch(searchTerm, () => {
  selectedIndex.value = undefined;
});
watch(
  () => props.selectedItem,
  () => {
    if (props.selectedItem === undefined && props.defaultIndex !== undefined) {
      selectedIndex.value = props.defaultIndex;
      emit('selected', props.items[selectedIndex.value]);
    }
  }
);
</script>
