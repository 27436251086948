const formatNumber = (n, d) => {
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: d,
    maximumFractionDigits: d
  }).format(n);
};

const isInvalid = value => {
  if (typeof value === 'undefined' || value === null || value === '' || isNaN(value)) {
    return true;
  }
  return false;
};

export function useNumberFormatter(value) {
  if (isInvalid(value)) {
    return '-';
  }
  return formatNumber(value, 0);
}

export function usePriceFormatter(value) {
  if (isInvalid(value)) {
    return '-';
  }
  let decimals = Math.abs(value) < 1 ? 6 : 2;
  let prefix = value < 0 ? '-' : '';
  value = value < 0 ? value * -1 : value;
  return `${prefix}$${formatNumber(value, decimals)}`;
}

export function usePercentFormatter(value) {
  if (isInvalid(value)) {
    return '-';
  }
  return parseFloat(value) > 0 ? `+${value}%` : `${value}%`;
}

export function useRoundedNumber(value, decimals = 2) {
  if (isInvalid(value)) {
    return '-';
  }
  const decimalNumber = typeof value === 'string' ? parseFloat(value) : value;
  return formatNumber(decimalNumber, decimals);
}

export function useRoundedSmallPercent(value, minDecimals = 2, maxDecimals = 4, small = true) {
  if (typeof value === 'undefined' || value === null || value === '') {
    return '-';
  }
  if (small && value < 0.01) {
    minDecimals = 4;
    maxDecimals = 4;
  }
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: minDecimals,
    maximumFractionDigits: maxDecimals
  }).format(value);
}

export function useBigNumberFormatter(num, digits = 2, prefix = '') {
  if (isInvalid(num)) {
    return '-';
  }
  var si = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'B' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' }
  ];
  var negative = [
    { value: -1, symbol: '' },
    { value: -1e3, symbol: 'k' },
    { value: -1e6, symbol: 'M' },
    { value: -1e9, symbol: 'B' },
    { value: -1e12, symbol: 'T' },
    { value: -1e15, symbol: 'P' },
    { value: -1e18, symbol: 'E' }
  ];
  var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var i;
  if (num >= 0) {
    for (i = si.length - 1; i > 0; i--) {
      if (num >= si[i].value) {
        break;
      }
    }
  } else {
    for (i = si.length - 1; i > 0; i--) {
      if (num <= negative[i].value) {
        break;
      }
    }
  }
  let value = (num / si[i].value).toFixed(digits).replace(rx, '$1');
  if (value < 0) {
    value = '-' + prefix + Math.abs(value);
  } else {
    value = prefix + value;
  }
  return value + si[i].symbol;
}

export function truncate(value, maxChars) {
  if (value && value.length > maxChars) {
    return value.slice(0, maxChars) + '...';
  }
  return value;
}

export function usdCryptoPrice(value) {
  if (typeof value === 'undefined' || value === null || value === '') {
    return '-';
  }
  var decimals = Math.abs(value) < 1 ? 6 : 2;
  var prefix = value < 0 ? '-' : '';
  value = value < 0 ? value * -1 : value;
  return (
    prefix +
    '$' +
    new Intl.NumberFormat('en-US', {
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals
    }).format(value)
  );
}

export function handleCoinClick(coin) {
  //WIP - Should be specific to the parent URL
  const allowedReceivers = '*';
  window.parent.postMessage(
    {
      event: 'coin',
      coin: { id: coin.id, name: coin.name, ticker: coin.ticker }
    },
    allowedReceivers
  );
}
