export default {
  team_roles: [],
  user_roles: [],
  session: {
    logged: false,
    token: '',
    user_id: null,
    team_id: null,
    user_email: '',
    roles: [],
    permissions: [],
    status: ''
  },
  data: {
    coins: [],
    verifiedCoins: [],
    universal: {},
    watchlists: {
      coins: [],
      companies: []
    }
  },
  defaultFeeds: ['Firehose', 'Trending', 'Starred by The TIE', 'Starred by My Team'],
  loadingStatus: {
    coins: false,
    static: false
  },
  notifications: {
    long: 10000,
    short: 5000
  },
  navItems: [
    {
      id: 'dashboard',
      name: 'Dashboard',
      link: '/dashboard',
      priority: 8
    },
    {
      id: 'news',
      name: 'News',
      link: '/news',
      priority: 7
    },
    {
      id: 'alerts',
      name: 'Alerts',
      link: '/alerts',
      priority: 6
    },
    {
      id: 'screener',
      name: 'Screener',
      link: '/screener',
      subpages: [],
      hideSubNav: true,
      priority: 6
    },
    {
      id: 'tradingView',
      name: 'Chart',
      link: '/charts',
      priority: 5,
      subNavItems: [
        {
          id: 'tradingView',
          name: 'Trading Chart',
          link: '/charts'
        },
        {
          id: 'tradingView.research',
          name: 'Research Chart',
          link: '/charts/research'
        }
      ]
    },
    {
      id: 'tools',
      name: 'Tools',
      link: '/tools',
      priority: 5,
      subNavItems: [
        // {
        //   id: 'calendar',
        //   name: 'Calendar',
        //   link: '/tools'
        // },
        {
          id: 'apefluencer',
          name: 'Apefluencer',
          link: '/tools/apefluencer'
        },
        // {
        //   id: 'research',
        //   name: 'Research',
        //   link: '/tools/research'
        // },
        {
          id: 'derivatives',
          name: 'Derivatives',
          link: '/tools/derivatives'
        },
        {
          id: 'unlock',
          name: 'Token Unlocks',
          link: '/tools/unlock'
        },
        {
          id: 'governance',
          name: 'Governance',
          link: '/tools/governance'
        },
        {
          id: 'ecosystems',
          name: 'Ecosystems',
          link: '/tools/ecosystems'
        }
      ]
    },
    {
      id: 'coins',
      name: 'Coins',
      link: '/coins',
      priority: 4
    },
    {
      id: 'nfts',
      name: 'NFTs',
      link: '/nft',
      priority: 3
    },
    {
      id: 'companies',
      name: 'Companies',
      link: '/companies',
      subpages: [],
      hideSubNav: true,
      priority: 3
    },
    {
      id: 'research',
      name: 'Research',
      link: '/research',
      priority: 2
    }
    // ,
    // {
    //   id: 'general',
    //   name: 'General',
    //   link: '/general',
    //   subNavItems: [
    //     {
    //       id: 'sec_filings',
    //       name: 'SEC Filings',
    //       link: '/general'
    //     },
    //     {
    //       id: 'regulation',
    //       name: 'Regulation',
    //       link: '/general/regulation'
    //     },
    //     {
    //       id: 'court_cases',
    //       name: 'Court Cases',
    //       link: '/general/court_cases'
    //     },
    //     {
    //       id: 'central_banks',
    //       name: 'Central Banks',
    //       link: '/general/central_banks'
    //     }
    //   ]
    // }
  ],
  companyTagGroups: ['Companies'],
  sidebarData: {},
  sidebars: {
    coin: false,
    correlated: false
  },
  path: 'dashboard',
  firstPath: '',
  lastPath: '',
  searchMode: false,
  categories: {
    company: []
  },
  tagColors: {
    Coins: 'text-green-400',
    Companies: 'text-yellow-400',
    'General Topics': 'text-blue-400',
    SigDevs: 'text-red-400',
    'Low Quality': 'text-gray-400',
    Social: 'text-blue-300'
  },
  tagBackgroundColors: {
    Coins: 'bg-green-400',
    Companies: 'bg-yellow-400',
    'General Topics': 'bg-blue-400',
    SigDevs: 'bg-red-400',
    'Low Quality': 'bg-gray-400',
    Social: 'bg-blue-300'
  },
  sentimentClasses: {
    'Very Bearish': 'text-red-400',
    Bearish: 'text-red-400',
    Neutral: 'text-gray-400',
    Bullish: 'text-green-400',
    'Very Bullish': 'text-green-400'
  },
  companySlugs: [],
  coinDatapointsConfig: [],
  sigdevTagSubgroup: [],
  sidebarCollapsed: localStorage.getItem('sidebarCollapsed') === 'true',
  sidebarCollapsedInt: localStorage.getItem('sidebarCollapsed') === 'true',
  hasSidebar: false,
  modal: {
    name: '',
    status: false,
    payload: {}
  },
  metricTooltips: [],
  userNotifications: [],
  sentimentColumns: [
    'hourly_sentiment',
    'daily_sentiment_avg_30_days',
    'daily_sentiment_change_7_days',
    'daily_sentiment',
    'long_term_sentiment',
    'tweet_volume',
    'tweet_volume_1_minute',
    'tweet_volume_30_day_avg',
    'hype_to_activity_ratio',
    'tweet_volume_dominance',
    'tweet_unique_sources_percent_30_day_avg',
    'twitter_accounts',
    'nvtweet_ratio',
    'twitter_accounts_30_day_avg',
    'relative_tweet_volume',
    'tweet_unique_sources_percent',
    'tweet_unique_sources_percent_1_minute',
    'positive_tweets_percent',
    'negative_tweets_percent',
    'neutral_tweets_percent'
  ],
  tvlColumns: [
    'change_1d',
    'change_7d',
    'tvl',
    'tvl_mcap',
    'change_30d',
    'retail_balance',
    'retail_supply_pct',
    'retail_balance_1d',
    'retail_supply_pct_1d',
    'retail_balance_7d',
    'retail_supply_pct_7d',
    'retail_balance_30d',
    'retail_supply_pct_30d',
    'retail_balance_1d_change_pct',
    'retail_balance_7d_change_pct',
    'retail_balance_30d_change_pct',
    'early_holder_balance',
    'early_holder_supply_pct',
    'early_holder_balance_1d',
    'early_holder_supply_pct_1d',
    'early_holder_balance_7d',
    'early_holder_supply_pct_7d',
    'early_holder_balance_30d',
    'early_holder_supply_pct_30d',
    'early_holder_balance_1d_change_pct',
    'early_holder_balance_7d_change_pct',
    'early_holder_balance_30d_change_pct',
    'whale_balance',
    'whale_supply_pct',
    'whale_balance_1d',
    'whale_supply_pct_1d',
    'whale_balance_7d',
    'whale_supply_pct_7d',
    'whale_balance_30d',
    'whale_supply_pct_30d',
    'whale_balance_1d_change_pct',
    'whale_balance_7d_change_pct',
    'whale_balance_30d_change_pct',
    'num_inc_24hr',
    'num_dec_24hr',
    'num_txs_24hr',
    'num_active_addrs_24hr',
    'num_inc_30d_avg',
    'num_dec_30d_avg',
    'num_txs_30d_avg',
    'num_active_addrs_30d_avg',
    'num_inc_24hr_rel_change_pct',
    'num_dec_24hr_rel_change_pct',
    'num_txs_24hr_rel_change_pct',
    'num_active_addrs_24hr_rel_change_pct',
    'institution_balance',
    'institution_supply_pct',
    'institution_balance_1d',
    'institution_supply_pct_1d',
    'institution_balance_7d',
    'institution_supply_pct_7d',
    'institution_balance_30d',
    'institution_supply_pct_30d',
    'institution_balance_1d_change_pct',
    'institution_balance_7d_change_pct',
    'institution_balance_30d_change_pct',
    '1h_exchange_flow_supply_pct',
    '24h_exchange_flow_supply_pct',
    '1h_exchange_flow_usd',
    '24h_exchange_flow_usd',
    'total_supply_held_pct'
  ],
  developmentColumn: ['commit_count', 'active_developer', 'commit_count_change_30d', 'active_users_change_30d'],
  widgetOutputChannels: {
    channel_a: null,
    channel_b: null,
    channel_c: null,
    channel_d: null,
    channel_e: null,
    channel_f: null,
    channel_g: null,
    channel_h: null
  },
  streamUserToken: null,
  streamClient: null,
  streamUser: null,
  giphyApiKey: null,
  unreadChannelsCount: null,
  previewImage: null,
  streamCompanies: null,
  channels: [],
  rejectedChannels: [],
  mutedChannels: [],
  requestChannels: [],
  messagingAccess: null,
  streamUsers: [],
  folders: [],
  confirmationDialogOptions: {
    show: false,
    resolve: null,
    title: 'Action Required',
    message: 'Are you sure?',
    confirmText: 'Proceed',
    rejectText: 'Cancel'
  },
  apiRoles: ['sentiment_api', 'sigdev_api', 'onchain_api', 'api_user'],
  nftChain: 'ethereum',
  coinChain: 'ethereum',
  chartColors: [
    '#3B82F6',
    '#EF4444',
    '#10B981',
    '#F59E0B',
    '#D946EF',
    '#60A5FA',
    '#F87171',
    '#34D399',
    '#FBBF24',
    '#E879F9',
    '#2563EB',
    '#DC2626',
    '#059669',
    '#D97706',
    '#C026D3',
    '#1D4ED8',
    '#B91C1C',
    '#047857',
    '#845309',
    '#A21CAF',
    '#1E40AF',
    '#991B1B',
    '#065F46',
    '#92400E',
    '#86198F'
  ],
  channelsLoaded: false
};
