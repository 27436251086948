<template>
  <div class="h-full" :id="id">
    <NexoWidgetsBase
      :widget="widget"
      :base-theme="baseTheme"
      :loading="loader"
      :widget-access-level="widgetAccessLevel"
    >
      <template #body>
        <div class="flex h-full flex-col p-6">
          <div class="pb-2">
            <div class="flex items-center space-x-2">
              <span>
                <NexoDropdownSearch
                  class="-mt-0.5"
                  :truncate-value="20"
                  :active-item="selectedMetric"
                  @selected="selectMetric"
                  custom-classes="mt-2"
                  :items="metrics"
                  dropdown-width="200px"
                />
              </span>
              <span>
                <NexoDropdownSearch
                  class="-mt-0.5"
                  custom-classes="mt-2"
                  :truncate-value="20"
                  @selected="selectedCategory = $event.id"
                  :items="categoryTypeOptions"
                  :active-item="selectedCategory"
                  dropdown-width="200px"
                />
              </span>
              <NexoButton
                @press="resetConfiguration"
                style="min-height: 22px"
                size="sm"
                type="secondary"
                class="bg-skin-btn-secondary-accent -mt-0.5 rounded !py-0"
              >
                Reset
              </NexoButton>
            </div>
          </div>
          <NexoNoDataError v-show="!chartData.length" class="h-full" />
          <div v-show="chartData.length" class="flex-1">
            <div id="sector-performance" class="h-full"></div>
          </div>
        </div>
      </template>
    </NexoWidgetsBase>
  </div>
</template>
<script setup>
import { useStore } from 'vuex';
import { ref, computed, onMounted, watch, nextTick } from 'vue';
import colors from 'tailwindcss/colors';
import Highcharts from 'highcharts/highcharts';
import treemap from 'highcharts/modules/treemap';

treemap(Highcharts);
const store = useStore();
const props = defineProps({
  widget: { type: Object, required: true },
  baseTheme: { type: Object },
  overrides: { type: Object },
  templateId: { type: String },
  widgetAccessLevel: { type: String }
});

//CHART
const chart = ref(null);
const chartData = ref([]);
const chartOptions = computed(() => {
  return {
    chart: {
      marginBottom: 0,
      marginRight: 0,
      marginLeft: 0
    },
    xAxis: {
      crosshair: false
    },
    series: [
      {
        type: 'treemap',
        layoutAlgorithm: 'squarified',
        borderColor: '#16181D',
        borderWidth: 1,
        states: {
          hover: {
            enabled: true,
            borderColor: colors.gray[900],
            opacity: 1
          }
        },
        point: {
          events: {
            mouseOver: function () {
              this.graphic.parentGroup.toFront();
            },
            mouseOut: function () {
              this.graphic.parentGroup.toFront();
            },
            click: function (event) {
              const parts = selectedCategory.value.split('-');
              const type = parts[0];
              if (type === 'all') {
                selectedCategory.value = `category-${event.point.id}`;
              } else if (type === 'category') {
                selectedCategory.value = `subcategory-${event.point.id}`;
              }
            }
          }
        },
        data: chartData.value,
        dataLabels: {
          overflow: 'none',
          crop: false
        }
      }
    ],
    tooltip: {
      backgroundColor: colors.gray[800],
      style: {
        color: 'white',
        pointerEvents: 'auto'
      },
      followPointer: false,
      padding: 0,
      pointFormatter: function () {
        let tipHtml = `<div class='break-words rounded bg-gray-800 px-4 py-2'>`;
        return (
          tipHtml +
          this.name +
          `<span class="ml-2">${this.isNegative ? `-${this.value}%` : `+${this.value}%`}</span></div>`
        );
      }
    },
    plotOptions: {
      treemap: {
        dataLabels: {
          useHTML: true,
          style: {
            textOutline: 'none'
          },
          formatter: function () {
            let color = this.point.isNegative ? 'text-red-200' : 'text-green-200';
            return `<div class="space-y-1 font-medium px-2">
              <div class="truncate whitespace-normal text-sm">${this.point.name}</div>
              <div class="text-xs ${color}">${this.point.isNegative ? '-' : ''}${this.point.value}%</div>
            </div>`;
          },
          align: 'left',
          verticalAlign: 'top',
          enabled: true
        },
        cursor: selectedCategory.value.includes('subcategory') ? '' : 'pointer'
      }
    }
  };
});
onMounted(() => {
  plotChart();
  drawSavedCategory();
});
function plotChart() {
  nextTick(() => {
    if (chartData.value.length && !loader.value && document.getElementById('sector-performance')) {
      chart.value = Highcharts.chart('sector-performance', chartOptions.value);
    }
  });
}
watch(chartData, () => {
  if (chart.value) {
    chart.value.series[0].update({ data: chartData.value });
  }
});

const colorsRange = ref({
  red: [
    '#5B2023',
    '#521d20',
    '#491a1c',
    '#882F35',
    '#7a2a30',
    '#6d262a',
    '#B63F46',
    '#a4393f',
    '#923238',
    '#E34F58',
    '#cc474f',
    '#b63f46',
    '#E87078',
    '#cc474f',
    '#b63f46'
  ],
  green: [
    '#1A4A2C',
    '#174328',
    '#153b23',
    '#267042',
    '#22653b',
    '#1e5a35',
    '#339558',
    '#2e864f',
    '#297746',
    '#40BA6E',
    '#3aa763',
    '#339558',
    '#62C972',
    '#58b567',
    '#4ea15b'
  ]
});

//METRICS
const selectedMetric = ref('price_return_24_hours');
const metrics = ref([
  // price metrics
  { text: '1hr Price Change', id: 'price_return_1_hour' },
  { text: '24hr Price Change', id: 'price_return_24_hours' },
  { text: '7d Price Change', id: 'price_return_7_days' },
  { text: '30d Price Change', id: 'price_return_30_days' },
  { text: '1hr vs BTC Price Change', id: 'price_return_btc_1_hour' },
  { text: '24hr vs BTC Price Change', id: 'price_return_btc_24_hours' },
  { text: '7d vs BTC Price Change', id: 'price_return_btc_7_days' },
  { text: '30d vs BTC Price Change', id: 'price_return_btc_30_days' },

  // sentiment metrics
  { text: 'Daily Sentiment Change 7 Days', id: 'daily_sentiment_change_7_days' },

  // news metrics
  { text: 'Relative News Volume', id: 'relative_news' },

  // twitter metrics
  { text: 'Relative Tweet Volume', id: 'relative_tweet_volume' },

  // volume metrics
  { text: 'Relative Trade Volume', id: 'relative_trade_volume' }
]);
const both = computed(() => {
  return width.value >= height.value * 1.66 || width.value >= 700;
});
function selectMetric(handle) {
  selectedMetric.value = handle.id;
}
watch(selectedMetric, () => {
  updateForSelectedId();
});

function resetConfiguration() {
  selectedMetric.value = 'price_return_24_hours';
  selectedCategory.value = 'all';
}

function drawSavedCategory() {
  if (props.widget.configuration && props.widget.configuration.active_category) {
    selectedCategory.value = props.widget.configuration.active_category;
  } else {
    selectedCategory.value = 'all';
  }

  if (props.widget.configuration && props.widget.configuration.selected_metric) {
    selectedMetric.value = props.widget.configuration.selected_metric;
  }
}
function updateForSelectedId() {
  if (!selectedCategory.value) {
    return;
  }

  const parts = selectedCategory.value.split('-');
  const type = parts[0];

  if (type === 'all') {
    drawMain();
  } else if (type === 'category') {
    drawSubCategory(parseInt(parts[1]));
  } else if (type === 'subcategory') {
    drawCoin(parseInt(parts[1]));
  }
}

//CATEGORIES
const categories = computed(() => {
  return store.state.categories.coin;
});
const coinsByCategory = computed(() => {
  const byCategory = {};
  const coinsBySubCategories = coinsBySubCategory.value;

  if (!coinsBySubCategories) {
    return [];
  }

  subCategories.value?.forEach(subCategory => {
    const parentCategory = subCategory.category_id;

    if (!byCategory[parentCategory]) {
      byCategory[parentCategory] = {};
    }

    (coinsBySubCategories[subCategory.id] || []).forEach(coin => {
      byCategory[parentCategory][coin.uid] = coin;
    });
  });

  const byCategoryList = {};

  Object.keys(byCategory)?.forEach(k => {
    byCategoryList[k] = Object.values(byCategory[k]);
  });

  return byCategoryList;
});
const metricWeightedByCategories = computed(() => {
  const metric = selectedMetric.value;
  const category = selectedCategory.value;

  let activeCategories = categories.value?.map((category, index) => {
    let sum = 0;
    let total = 0;
    let realMarketCapTotal = 0;

    const coinsInCategory = coinsByCategory.value[category.id];

    if (coinsInCategory) {
      coinsInCategory?.forEach(coin => {
        const mCap = parseFloat(coin.market_cap);
        const metricVal = parseFloat(coin[metric]);

        if (mCap && metricVal) {
          total += mCap;
          sum += metricVal * mCap;
        }

        if (mCap) {
          realMarketCapTotal += mCap;
        }
      });
    }

    return {
      name: category.name,
      id: category.id,
      value: formatPercent((sum / total) * 100)
    };
  });
  return setDatapointColors(activeCategories);
});
function drawMain() {
  selectedCategory.value = 'all';
  chartData.value = metricWeightedByCategories.value;
}
watch(categories, () => {
  updateForSelectedId();
});

//SUBCATEGORIES
const subCategories = computed(() => {
  return store.state.categories.coin_sub_categories;
});
function metricWeightedBySubCategories(categoryId) {
  const metric = selectedMetric.value;

  let activeSubcategories = subCategories.value
    .map((subCategory, index) => {
      if (subCategory.category_id != categoryId) {
        return undefined;
      }

      let sum = 0;
      let total = 0;
      let realMarketCapTotal = 0;

      const coinsInCategory = coinsBySubCategory.value[subCategory.id];

      if (coinsInCategory) {
        coinsInCategory?.forEach(coin => {
          const mCap = parseFloat(coin.market_cap);
          const metricVal = parseFloat(coin[metric]);

          if (mCap && metricVal) {
            total += mCap;
            sum += metricVal * mCap;
          }

          if (mCap) {
            realMarketCapTotal += mCap;
          }
        });
      }

      return {
        name: subCategory.name,
        id: subCategory.id,
        value: formatPercent((sum / total) * 100)
      };
    })
    .filter(cat => {
      return cat;
    });
  return setDatapointColors(activeSubcategories);
}
function drawSubCategory(id) {
  selectedCategory.value = `category-${id}`;
  chartData.value = metricWeightedBySubCategories(id);
}

//COINS
const coins = computed(() => {
  return store.getters.coins;
});
const loader = computed(() => {
  return store.getters.coinsLoader || store.getters.categoriesLoader;
});
watch(coins, () => {
  // plotChart();
});
function coinsInSubcategory(subCategoryId) {
  const metric = selectedMetric.value;
  const subCat = subCategories.value.find(s => {
    return s.id === subCategoryId;
  });
  let activeCoins = coinsBySubCategory.value[subCat.id]?.map((coin, index) => {
    return {
      name: coin.name,
      id: coin.uid,
      value: formatPercent(coin[metric] * 100)
    };
  });
  return setDatapointColors(activeCoins);
}
const coinsBySubCategory = computed(() => {
  const bySubCategory = {};

  coins.value?.forEach(coin => {
    coin.category_ids?.forEach(subCategoryId => {
      if (!bySubCategory[subCategoryId]) {
        bySubCategory[subCategoryId] = [];
      }

      bySubCategory[subCategoryId].push(coin);
    });
  });

  return bySubCategory;
});
function drawCoin(id) {
  selectedCategory.value = `subcategory-${id}`;
  chartData.value = coinsInSubcategory(id);
}
watch(coins, () => {
  updateForSelectedId();
});

//SELECTED CATEGORY
const selectedCategory = ref(undefined);
function selectCategory(handle) {
  selectedCategory.value = handle.id;
  updateForSelectedId();
}
const categoryTypeOptions = computed(() => {
  const options = [{ text: 'All Coins', id: 'all' }];

  categories.value?.forEach(category => {
    options.push({
      text: category.name,
      id: `category-${category.id}`
    });
    let subcategoryOptions = subCategories.value
      .filter(subCat => {
        return subCat.category_id === category.id && coinsBySubCategory.value[subCat.id];
      })
      .map(elm => {
        return {
          text: elm.name,
          id: `subcategory-${elm.id}`
        };
      });
    options.push(...subcategoryOptions);
  });
  return options;
});
watch(selectedCategory, () => {
  updateForSelectedId();
});

//HELPERS
function formatPercent(p) {
  return Math.round(p * 100) / 100;
}
function setDatapointColors(category) {
  if (!category) {
    return [];
  }
  let negIndex = -1;
  let posIndex = -1;
  return category.map(c => {
    if (c.value < 0) {
      negIndex = negIndex == colorsRange.value.red.length - 1 ? 0 : negIndex + 1;
      return {
        ...c,
        value: c.value * -1,
        color: colorsRange.value.red[negIndex],
        isNegative: true
      };
    } else {
      posIndex = posIndex == colorsRange.value.green.length - 1 ? 0 : posIndex + 1;
      return {
        ...c,
        color: colorsRange.value.green[posIndex],
        isNegative: false
      };
    }
  });
}

//WIDGET SIZES
const width = ref(0);
const height = ref(0);
const id = ref(props.widget.uuid || Math.random());
onMounted(() => {
  setWidgetSize();
});
function setWidgetSize() {
  const widgetElem = document.getElementById(id.value);
  if (widgetElem) {
    width.value = widgetElem.clientWidth;
    height.value = widgetElem.clientHeight;
  }
}
</script>
