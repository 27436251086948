<template>
  <div class="h-full" :id="id">
    <NexoWidgetsBase :widget="widget" :base-theme="baseTheme" :loading="coinsLoader">
      <template #body>
        <NexoTableBase
          :config="tableConfig"
          :data="tableCoins"
          :loading="coinsLoader"
          @sort-screener="updateSortConfig"
          @btn-clicked="postCoinTradeMessage"
          @coin-clicked="handleCoinClick"
          :default-sort-order="currentSortConfig ? currentSortConfig.order : undefined"
          :default-sort-column="currentSortConfig ? currentSortConfig.col : undefined"
        />
      </template>
    </NexoWidgetsBase>
  </div>
</template>
<script setup>
import { ref, onMounted, computed, inject, watch, onBeforeUnmount } from 'vue';
import { handleCoinClick } from '@/composeables/integrations/nexo/helpers';
import { useStore } from 'vuex';
const $store = useStore();
const $http = inject('http');

const props = defineProps({
  widget: { type: Object, required: true },
  baseTheme: { type: Object },
  overrides: { type: Object },
  templateId: { type: String },
  tvlCoins: { type: Array },
  widgetAccessLevel: { type: String }
});

onMounted(() => {
  window.addEventListener('message', event => {
    if (event.source === window.parent && event.data?.event == 'search') {
      searchTerm.value = event.data.value;
    }
  });
});
onBeforeUnmount(() => {
  window.removeEventListener('message');
});

//COINS
const searchTerm = ref('');
const allCoins = computed(() => {
  return $store.getters.coins;
});
const tableCoins = computed(() => {
  let filteredCoins = [...allCoins.value].filter(x => {
    return searchTerm.value ? x.ticker.toLowerCase().includes(searchTerm.value.toLowerCase()) : true;
  });
  filteredCoins = filteredCoins.map(coin => {
    if (!props.tvlCoins.includes(coin.uid)) {
      return { ...coin, tvl: null, change_7d: null };
    }
    return coin;
  });
  return filteredCoins.map(x => {
    return {
      ...x,
      trade: { text: 'Trade' }
    };
  });
});

//TABLE METRICS
const layoutMetrics = ref({
  restricted: ['price', 'price_return_24_hours', 'market_cap', 'trading_volume', 'circulating_supply', 'max_supply'],
  full: [
    'price',
    'price_return_24_hours',
    'trading_volume',
    'market_cap',
    'relative_trade_volume',
    'tweet_volume',
    'relative_tweet_volume',
    'tvl',
    'change_7d',
    '1h_exchange_flow_supply_pct',
    'daily_sentiment'
  ]
});
const selectedMetrics = computed(() => {
  let metrics = ['#', 'coin'];
  metrics.push(...layoutMetrics.value[props.widgetAccessLevel]);
  return metrics;
});
const watchlist = ref(false);
onMounted(() => {
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has('watchlist')) {
    watchlist.value = urlParams.get('watchlist');
  }
});

//TABLE CONFIG
const allDatapoints = computed(() => {
  return $store.getters.coinDatapoints;
});
const tableConfig = computed(() => {
  let general = {
    cols: [],
    headerClass: 'py-8 sticky top-0 bg-skin-fill z-30 capitalize',
    horizontalScroll: true,
    rowClass: 'py-2 duration-100'
  };
  selectedMetrics.value.forEach(metric => {
    general.cols.push(allDatapoints.value.find(x => x.id == metric));
  });
  general.cols = general.cols.map(x => {
    return {
      ...x,
      enabled: true
    };
  });

  if (width.value < 300) {
    general.cols = general.cols.filter(x => x.id != '#');
  }
  general.cols.push({ id: 'trade', name: '', type: 'button' });
  return general;
});

//SORT CONFIG
const currentSortConfig = ref({
  col: { type: 'dollar_format', size: 1, enabled: true, id: 'market_cap', name: 'Market Cap', category: 'market' },
  order: 'ASC',
  type: 'number'
});
const localStorageAccessible = ref(false);
onMounted(async () => {
  await checkLocalStorage();
  if (localStorageAccessible.value) {
    currentSortConfig.value = JSON.parse(localStorage.getItem('nexo_screener_sort_config')) || currentSortConfig.value;
  }
});
function updateSortConfig(config) {
  currentSortConfig.value = config;
  if (localStorageAccessible.value) {
    localStorage.setItem('nexo_screener_sort_config', JSON.stringify(currentSortConfig.value));
  }
}
function checkLocalStorage() {
  var test = 'test';
  try {
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    localStorageAccessible.value = true;
  } catch (e) {
    localStorageAccessible.value = false;
  }
}

//LOADING
const loading = ref(true);
const coinsLoader = computed(() => {
  return $store.getters.coinsLoader;
});
const dataLoader = computed(() => {
  return coinsLoader.value || loading.value;
});

//WATCHLIST COINS
const watchlist_ids = ref([]);
const coin_tickers = ref([]);
async function loadUserWatchlists() {
  try {
    loading.value = true;
    let response = await $http.get('/integrations/watchlists');
    let watchlists = response.data;
    coin_tickers.value = [...new Set(watchlists.map(x => x.entities).flat())];
    watchlist_ids.value = allCoins.value.filter(c => coin_tickers.value.includes(c.ticker)).map(c => c.id);
    loading.value = false;
  } catch {
    //do nothing
  }
}

//WIDGET WIDTH
const id = ref(props.widget.uuid || Math.random());
const width = ref(0);
onMounted(async () => {
  updateWidth();
  const elm = document.getElementById(id.value);
  if (ResizeObserver) {
    new ResizeObserver(() => {
      updateWidth();
    }).observe(elm);
  }
});
function updateWidth() {
  const elm = document.getElementById(id.value);
  if (elm) {
    width.value = elm.clientWidth;
  }
}

//TRADE BUTTON
function postCoinTradeMessage(row) {
  //WIP - Should be specific to the parent URL
  const allowedReceivers = '*';
  window.parent.postMessage(
    {
      event: 'trade',
      coin: { id: row.coin_uid, name: row.name, ticker: row.ticker }
    },
    allowedReceivers
  );
}
</script>
