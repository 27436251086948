<template>
  <div class="h-full" :id="id">
    <NexoWidgetsBase
      :widget="widget"
      :base-theme="baseTheme"
      :loading="loading"
      :widget-access-level="widgetAccessLevel"
    >
      <template #body>
        <template v-if="trendingCoins.length">
          <div :class="width < 450 ? '' : 'flex'" class="overflow-y-auto">
            <div
              v-for="(coin, idx) in trendingCoins"
              @click="handleCoinClick(coin)"
              :key="coin.coin_uid"
              :class="[
                idx == 0 ? 'border-l' : '',
                width < 450 ? 'w-full' : 'w-1/5',
                'min-w-[15rem] cursor-pointer space-y-6 border-b border-r border-t border-gray-800 p-6 hover:bg-[#1B1D24] '
              ]"
            >
              <div class="flex items-center justify-between">
                <div class="cursor-pointer">
                  <div class="flex items-center space-x-3">
                    <img
                      :src="`https://terminal.cloudfront.thetie.io/coin_images/${coin.coin_uid}.png`"
                      class="h-7 w-7"
                    />
                    <span class="block text-sm font-medium uppercase text-gray-100">{{ coin.ticker }}</span>
                  </div>
                </div>
                <div>
                  <div>
                    <span class="block text-right text-sm font-medium text-gray-100">
                      {{ usePriceFormatter(coin.price) }}
                    </span>
                    <span
                      :class="[
                        coin.price_return_24_hours > 0 ? 'text-green-500' : 'text-red-500',
                        'block text-right text-sm font-medium'
                      ]"
                    >
                      {{ usePercentFormatter(useRoundedNumber(coin.price_return_24_hours)) }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="flex justify-between">
                <div class="flex flex-col space-y-1">
                  <NexoLabel>Tweets</NexoLabel>
                  <span class="block text-xl font-medium text-gray-100">
                    {{ useBigNumberFormatter(coin.tweet_volume) }}
                  </span>
                </div>
                <div class="flex flex-col space-y-1 text-right">
                  <NexoLabel>Tweet Volume</NexoLabel>
                  <NexoPercentage :percentage="coin[datapoint]" class="text-xl font-medium" />
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <NexoNoDataError />
        </template>
      </template>
    </NexoWidgetsBase>
  </div>
</template>
<script setup>
import { handleCoinClick } from '@/composeables/integrations/nexo/helpers';
import { useStore } from 'vuex';
import { ref, computed, onMounted } from 'vue';
import {
  usePriceFormatter,
  useBigNumberFormatter,
  usePercentFormatter,
  useRoundedNumber
} from '@/composeables/integrations/nexo/helpers';

const $store = useStore();
const props = defineProps({
  widget: { type: Object, required: true },
  baseTheme: { type: Object },
  overrides: { type: Object },
  templateId: { type: String },
  widgetAccessLevel: { type: String }
});

//LIMIT
const limit = ref(5);
onMounted(() => {
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has('limit')) {
    limit.value = urlParams.get('limit');
  }
});

//COINS
const datapoint = 'relative_tweet_volume';
const trendingCoins = computed(() => {
  const coins = $store.getters.coins;
  if (coins.length) {
    return coins
      .filter(x => datapoint in x && x[datapoint] !== null)
      .sort((a, b) => {
        return b[datapoint] - a[datapoint];
      })
      .slice(0, limit.value);
  }
  return [];
});

//WIDGET WIDTH
const id = ref(props.widget.uuid || Math.random());
const width = ref(0);
onMounted(async () => {
  updateWidth();
  const elm = document.getElementById(id.value);
  if (ResizeObserver) {
    new ResizeObserver(() => {
      updateWidth();
    }).observe(elm);
  }
});
function updateWidth() {
  const elm = document.getElementById(id.value);
  if (elm) {
    width.value = elm.clientWidth;
  }
}

const loading = computed(() => {
  return $store.getters.coinsLoader;
});
</script>
