import {
  fetchCoins,
  fetchUniversalData,
  fetchCategories,
  loadStaticData,
  loadUserNotifications,
  savePageVisits
} from '@/api/coinApi';
export default {
  setApiToken: ({ commit }, api_token) => {
    commit('setApiToken', api_token);
  },
  setUser: ({ commit }, user) => {
    commit('setUser', user);
  },
  setStreamClient: ({ commit }, payload) => {
    commit('setStreamClient', payload);
  },
  setGiphyKey: ({ commit }, payload) => {
    commit('setGiphyKey', payload);
  },
  updateStreamUser: ({ commit }, payload) => {
    commit('updateStreamUser', payload);
  },
  setUnreadChannelsCount: ({ commit }, payload) => {
    commit('setUnreadChannelsCount', payload);
  },
  setImageForPreview: ({ commit }, payload) => {
    commit('setImageForPreview', payload);
  },
  setStreamCompanies: ({ commit }, payload) => {
    commit('setStreamCompanies', payload);
  },
  setFolders: ({ commit }, payload) => {
    let data = payload.map(x => {
      return {
        folder_id: x.id,
        id: x.name,
        text: x.name,
        icon: 'IconFolder',
        group: 0,
        channels: x.channels
      };
    });
    commit('setFolders', data);
  },
  updateFolder: ({ commit }, payload) => {
    let data = {
      folder_id: payload.id,
      id: payload.name,
      text: payload.name,
      icon: 'IconFolder',
      group: 0,
      channels: payload.channels
    };
    commit('updateFolder', data);
  },
  deleteFolder: ({ commit }, payload) => {
    commit('deleteFolder', payload);
  },
  async setChannels({ commit, state }) {
    if (!state.streamClient) {
      return;
    }
    let client = state.streamClient;
    const filter = { members: { $in: [String(client.userID)] } };
    const sort = { updated_at: -1 };
    let all_channels = [];
    let limit = 30;
    all_channels = await client.queryChannels(filter, sort, {
      limit: 30,
      presence: true,
      watch: true,
      state: true
    });
    let channels = all_channels;
    while (channels.length >= limit) {
      all_channels = await client.queryChannels(filter, sort, {
        limit: 30,
        offset: limit,
        presence: true,
        watch: true,
        state: true
      });
      channels.push(...all_channels);
      limit += 30;
    }
    let rejectedChannels = channels
      .filter(c =>
        Object.values(c.state.members).length > 0
          ? Object.values(c.state.members).some(
              u => u.user_id === client.user.id && u.invited === true && !u.invite_accepted_at && u.invite_rejected_at
            )
          : false
      )
      .map(x => x.cid);
    let mutedChannels = await client.queryChannels({ muted: true, members: { $in: [String(client.userID)] } });
    let mutedChannelsCids = mutedChannels.length ? mutedChannels.map(c => c.cid) : [];

    let archiveChannels = state.streamUser?.archive_channels;
    let pinnedChannels = state.streamUser?.pinned_channels;
    let updatedData = {};
    if (archiveChannels) {
      archiveChannels = JSON.parse(JSON.stringify(archiveChannels));
      let filteredArchiveChannels = channels.map(c => c.cid).filter(x => archiveChannels.includes(x));
      filteredArchiveChannels = [...new Set([0, ...filteredArchiveChannels])]; // ensure uniqueness
      if (archiveChannels.filter(x => !filteredArchiveChannels.includes(x)).length) {
        updatedData.archive_channels = filteredArchiveChannels;
      }
    }
    if (pinnedChannels) {
      pinnedChannels = JSON.parse(JSON.stringify(pinnedChannels));
      let filteredPinnedChannels = channels.map(c => c.id).filter(x => pinnedChannels.includes(x));
      filteredPinnedChannels = [...new Set([0, ...filteredPinnedChannels])]; // ensure uniqueness
      if (pinnedChannels.filter(x => !filteredPinnedChannels.includes(x)).length) {
        updatedData.pinned_channels = filteredPinnedChannels;
      }
    }
    if (Object.keys(updatedData).length) {
      client.partialUpdateUser({ id: state.streamUser.id, set: updatedData });
    }
    commit('setChannels', { channels: channels, rejectedChannels: rejectedChannels, mutedChannels: mutedChannelsCids });
  },
  setChannelLoaded: ({ commit }) => {
    commit('setChannelLoaded');
  },
  addMutedChannel: ({ commit }, payload) => {
    commit('addMutedChannel', payload);
  },
  removeMutedChannel: ({ commit }, payload) => {
    commit('removeMutedChannel', payload);
  },
  async setStreamUsers({ commit, state }) {
    let sort = { user_id: 1 };
    let all_users = [];
    let limit = 100;
    all_users = await state.streamClient.queryUsers(
      { id: { $nin: ['bot_user', 'the-tie', 'thetiedev'] }, compliance_user: false },
      sort,
      {
        limit: limit,
        offset: 0,
        presence: true
      }
    );
    let temp_users = all_users.users;
    while (temp_users.length >= limit) {
      all_users = await state.streamClient.queryUsers(
        {
          user_id_gt: temp_users[temp_users.length - 1].user_id,
          id: { $nin: ['bot_user', 'the-tie', 'thetiedev'] },
          compliance_user: false
        },
        sort,
        {
          limit: 100,
          offset: limit,
          presence: true
        }
      );
      temp_users.push(...all_users.users);
      limit += 100;
    }
    if (temp_users.length) {
      temp_users = temp_users.filter(x => (x.searchable ? true : state.streamUser.team_id == x.team_id ? true : false));
    }
    commit('setStreamUsers', temp_users);
  },
  setRequestChannels: ({ commit }, payload) => {
    commit('setRequestChannels', payload);
  },
  removeStreamChannel: ({ commit }, payload) => {
    commit('removeStreamChannel', payload);
  },
  addStreamChannel: ({ commit }, payload) => {
    commit('addStreamChannel', payload);
  },
  updateStreamChannel: ({ commit }, payload) => {
    commit('updateStreamChannel', payload);
  },
  updateStreamMessages: ({ commit }, payload) => {
    commit('updateStreamMessages', payload);
  },
  setMessagingAccess: ({ commit }, payload) => {
    commit('setMessagingAccess', payload);
  },
  updateLivePrices: ({ commit, state }, prices) => {
    var coins = state.data.coins;
    coins.forEach(coin => {
      if (prices[coin.uid] && prices[coin.uid] > 0) {
        // var addClass = '';
        // if (prices[coin.uid] > coin.price) {
        //   addClass = 'text-green-400';
        // } else if (prices[coin.uid] < coin.price) {
        //   addClass = 'text-red-400';
        // }
        // if (addClass) {
        //   var element = document.getElementsByClassName(coin.uid + '_price');
        //   for (var i = 0; i < element.length; i++) element.item(i).className += ' ' + addClass;
        //   setTimeout(() => {
        //     for (var i = 0; i < element.length; i++) {
        //       element[i].classList.remove(addClass);
        //     }
        //   }, 3000);
        // }
        commit('updateCoinPrice', { uid: coin.uid, latestPrice: prices[coin.uid] });
      }
    });
  },
  enableCoinSidebar: ({ commit }, payload) => {
    commit('setSidebarData', payload);
    commit('setSidebar', { type: 'coin', status: true });
  },
  resetCoinSidebar: ({ commit }) => {
    commit('setSidebarData', {});
    commit('setSidebar', { type: 'coin', status: false });
  },
  enableCorrelatedSidebar: ({ commit }, payload) => {
    commit('setSidebarData', payload);
    commit('setSidebar', { type: 'correlated', status: true });
  },
  resetCorrelatedSidebar: ({ commit }) => {
    commit('setSidebarData', {});
    commit('setSidebar', { type: 'correlated', status: false });
  },
  async setCoins({ commit }) {
    commit('setCoinsLoader', true);
    let coins = await fetchCoins();
    commit('setCoins', coins);
    commit('setCoinsLoader', false);
  },
  async updateCoins({ commit }) {
    commit('setCoins', await fetchCoins());
  },
  async setUniversal({ commit }) {
    commit('setUniversal', await fetchUniversalData());
  },
  async setCategories({ commit }) {
    commit('setCategories', await fetchCategories());
  },
  setNftChain({ commit }, payload) {
    commit('setNftChain', payload);
  },
  setCoinChain({ commit }, payload) {
    commit('setCoinChain', payload);
  },
  async loadCommonAppData({ commit }) {
    commit('setStaticDataLoader', true);
    const response = await loadStaticData();
    commit('setCategories', response.categories);
    commit('setCompanySlugs', response.company_slugs);
    commit('setSigdevTagSubgroup', response.sigdev_tagsubgroup);
    commit('setAllWatchlists', { data: response.watchlists });
    commit('setMetricTooltips', response.metric_tooltips);
    commit('setRoles', response.roles);
    commit('setStaticDataLoader', false);
    commit('setVerifiedCoins', response.verified_coins);
    commit('setCoinDatapoints', response.coin_datapoints);
  },
  async loadUserNotifications({ commit }) {
    const response = await loadUserNotifications();
    commit('setUserNotifications', response);
  },
  async savePageVisits({ commit }, payload) {
    await savePageVisits(payload);
  },
  confirm: ({ commit, state }, payload) => {
    commit('setConfirmationDialogOptions', payload);
    return new Promise((resolve, reject) => {
      const interval = setInterval(() => {
        let resolveStatus = state.confirmationDialogOptions.resolve;
        if (resolveStatus !== null) {
          if (resolveStatus) {
            resolve(true);
          } else {
            reject(true);
          }
          commit('resetConfirmationDialogOptions');
          clearInterval(interval);
        }
      }, 250);
    });
  }
};
