<template>
  <div class="h-full">
    <NexoWidgetsBase
      :widget="widget"
      :base-theme="baseTheme"
      :loading="loading"
      :widget-access-level="widgetAccessLevel"
    >
      <template #body>
        <template v-if="filteredData.length">
          <NexoTableBase class="bg-skin-fill" :config="config" :data="filteredData"></NexoTableBase>
        </template>
        <template v-else>
          <NexoNoDataError class="bg-skin-fill" />
        </template>
      </template>
    </NexoWidgetsBase>
  </div>
</template>
<script setup>
import { onMounted, ref, inject, computed, watch } from 'vue';
import { isEmpty } from 'lodash';
import { useStore } from 'vuex';
const $http = inject('http');
const store = useStore();

const props = defineProps({
  widget: { type: Object, required: true },
  baseTheme: { type: Object },
  overrides: { type: Object },
  templateId: { type: String },
  widgetAccessLevel: { type: String }
});

const config = ref({
  alternateBg: true,
  rowClass: 'py-2 duration-100',
  headerClass: 'sticky top-0',
  cols: [
    {
      type: 'link',
      name: 'Holder',
      id: 'tagLink',
      cellClass: 'text-xs !pl-0',
      divClass: 'text-xs font-normal truncate whitespace-nowrap !px-3',
      headerClass: 'text-right w-1/6 pr-2 pl-2'
    },
    {
      type: 'dollar_format',
      name: 'Balance',
      id: 'balance_amount',
      cellClass: 'text-xs',
      headerClass: 'w-1/6',
      divClass: 'text-xs',
      truncateLength: 25
    },
    {
      type: 'small_percent',
      name: 'Supply Held',
      id: 'pct_supply',
      multiply: false,
      cellClass: 'text-xs',
      divClass: 'text-xs',
      headerClass: 'w-1/6'
    },
    {
      type: 'percent',
      name: 'Change (1d)',
      id: '_1d_change_pct',
      cellClass: 'text-xs',
      multiply: false,
      divClass: 'text-xs',
      headerClass: 'w-1/6'
    },
    {
      type: 'percent',
      name: 'Change (7d)',
      id: '_7d_change_pct',
      cellClass: 'text-xs',
      multiply: false,
      divClass: 'text-xs',
      headerClass: 'w-1/6'
    },
    {
      type: 'percent',
      name: 'Change (30d)',
      id: '_30d_change_pct',
      cellClass: 'text-xs',
      headerClass: 'w-1/6',
      divClass: 'text-xs',
      multiply: false
    }
  ]
});

const coinTicker = ref('aave');
const chain = ref('ethereum');
onMounted(() => {
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has('coin')) {
    coinTicker.value = urlParams.get('coin').toLowerCase();
    getOwnershipData();
  }
  if (urlParams.has('chain')) {
    chain.value = urlParams.get('chain');
  }
});

//OWNERSHIP DATA
const ownershipData = ref([]);
const loading = ref(true);
const getOwnershipData = async () => {
  loading.value = true;
  $http
    .get('/integrations/ownership', {
      params: {
        coin: coinTicker.value
      }
    })
    .then(response => {
      ownershipData.value = response.data || [];
    })
    .catch(() => {
      ownershipData.value = [];
    })
    .finally(() => {
      loading.value = false;
    });
};
const filteredData = computed(() => {
  return ownershipData.value.map(i => {
    return {
      ...i,
      balance_amount: coinPrice.value ? i.balance * coinPrice.value : null,
      tagLink: {
        text: i.tag,
        link: `${chain.value === 'avalanche' ? 'https://snowtrace.io' : 'https://etherscan.io'}/address/${i.address}`
      }
    };
  });
});

//COIN
const coin = computed(() => store.getters.coins.find(c => c.ticker.toLowerCase() === coinTicker.value) || {});
const coinPrice = ref(null);
watch(
  () => coin.value,
  () => {
    if (!isEmpty(coin.value) && coinPrice.value == null) {
      coinPrice.value = coin.value?.price;
    }
  },
  { immediate: true }
);
</script>
