<template>
  <div class="h-full">
    <NexoWidgetsBase :widget="widget" :base-theme="baseTheme" :loading="alertDataLoader">
      <template #body>
        <NexoAlertConfiguration
          v-if="configureAlert"
          :active-alert="alertToEdit"
          :alert-templates="alertTemplates"
          :coin-ticker="coinTicker"
          @cancel="
            configureAlert = false;
            alertToEdit = null;
          "
          @created="
            configureAlert = false;
            existingAlerts.push($event);
          "
          @updated="
            configureAlert = false;
            existingAlerts = existingAlerts.map(obj => (obj.id == $event.id ? $event : obj));
          "
        />
        <div v-else class="space-y-4 py-4">
          <div class="flex justify-between px-4">
            <div class="text-base font-medium">Alerts</div>
            <button
              @click="configureAlert = true"
              class="base-button text-skin-btn-secondary-text bg-skin-btn-secondary-accent hover:bg-skin-btn-secondary-hover flex h-6 w-10 items-center justify-center border-0 px-2 text-xl font-medium outline-none duration-100 focus:outline-none"
            >
              +
            </button>
          </div>
          <NexoTags
            class="w-full space-x-4 px-4"
            :wrap-tags="false"
            tag-class="w-1/2 text-center py-2 flex items-center justify-center text-skin-base"
            :tags="[
              { id: 'past_alerts', label: 'Past Alerts' },
              { id: 'active_alerts', label: 'Active Alerts' }
            ]"
            v-model="selectedAlertTab"
          ></NexoTags>
          <div v-if="selectedAlertTab == 'active_alerts'">
            <NexoNoDataError class="pt-10" v-if="!Object.keys(coinAlerts).length" />
            <NexoActiveAlertItem
              v-for="coin in Object.keys(coinAlerts)"
              :key="coin"
              :coin-uid="coin"
              :coin-alerts="coinAlerts[coin]"
              :metric-mapping="metricMapping"
              :comparison-mapping="comparisonMapping"
              @edit="editAlert($event)"
              @delete="deleteAlert($event)"
            ></NexoActiveAlertItem>
          </div>
          <div v-else>
            <VirtualList
              v-if="pastAlerts.length"
              ref="scroller-past-alerts"
              :id="`past-alerts`"
              @tobottom="fetchPastAlerts"
              :data-key="'id'"
              :data-sources="pastAlerts"
              :data-component="NexoPastAlertItem"
            >
            </VirtualList>
            <NexoNoDataError class="pt-10" v-else />
          </div>
        </div>
      </template>
    </NexoWidgetsBase>
  </div>
</template>
<script setup>
import VirtualList from 'vue3-virtual-scroll-list';
import { ref, computed, onBeforeMount, onMounted, watch, inject } from 'vue';
import NexoPastAlertItem from '../NexoPastAlertItem.vue';

const http = inject('http');
const props = defineProps({
  widget: { type: Object, required: true },
  baseTheme: { type: Object },
  overrides: { type: Object },
  templateId: { type: String }
});

const selectedAlertTab = ref('active_alerts');

//ALERT CONFIGURATION
const configureAlert = ref(false);
const alertToEdit = ref(null);
function editAlert(alert) {
  alertToEdit.value = alert;
  configureAlert.value = true;
}
function deleteAlert(alerts) {
  http.post('/integrations/alerts/delete_all', { ids: alerts }).then(() => {
    existingAlerts.value = existingAlerts.value.filter(a => !alerts.includes(a.id));
  });
}

//ACTIVE ALERTS
const alertConfigurationsLoader = ref(true);
const existingAlerts = ref([]);
const alertTemplates = ref([]);
const coinAlerts = ref({});
const coinTicker = ref(null);
onBeforeMount(() => {
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has('coin')) {
    coinTicker.value = urlParams.get('coin').toLowerCase();
    configureAlert.value = true;
  }
  getAlertConfigurations();
});
async function getAlertConfigurations() {
  let response = await http.get('/integrations/alerts');
  existingAlerts.value = response?.data.alerts || [];
  alertTemplates.value = response?.data.templates || [];
  alertConfigurationsLoader.value = false;
}
watch(
  existingAlerts,
  () => {
    coinAlerts.value = [];
    existingAlerts.value.forEach(alert => {
      let coin = alert.config.coins[0];
      if (coinAlerts.value[coin]) {
        coinAlerts.value[coin].push(alert);
      } else {
        coinAlerts.value[coin] = [alert];
      }
    });
  },
  { deep: true }
);

//PAST ALERTS
const pastAlerts = ref([]);
const pastAlertsLoader = ref(false);
const noMoreResults = ref(false);
const pageParams = ref({
  page: 1,
  perPage: 100
});
onMounted(async () => {
  await fetchPastAlerts();
});
async function fetchPastAlerts() {
  if (!noMoreResults.value) {
    pastAlertsLoader.value = true;
    try {
      let response = await http.get('/integrations/notifications', {
        params: {
          limit: pageParams.value.perPage,
          offset: pageParams.value.perPage * (pageParams.value.page - 1)
        }
      });
      if (response.length == 0) {
        noMoreResults.value = true;
      }
      if (pageParams.value.page == 1) {
        pastAlerts.value = response.data;
      } else {
        pastAlerts.value.push(...response.data);
      }
      pageParams.value.page++;
    } catch {
      pastAlerts.value = [];
    } finally {
      pastAlertsLoader.value = false;
    }
  }
  return pageParams;
}

//LOADING
const alertDataLoader = computed(() => {
  return alertConfigurationsLoader.value || pastAlertsLoader.value;
});

//MAPPING TO DISPLAY
const metricMapping = ref({
  'Price Alert': 'Price Change',
  'Social Volume Alert': 'Social Volume',
  'Trading Volume Alert': 'Trading Volume',
  'On-Chain Activity Alert': 'On Chain Activity'
});
const comparisonMapping = ref({
  greater_than: 'Up',
  less_than: 'Down'
});
</script>
