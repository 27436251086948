<template>
  <div class="widget-container bg-skin-fill text-skin-base h-full w-full select-none overflow-hidden font-tt-norms">
    <template v-if="loading">
      <div class="h-full w-full animate-pulse bg-gray-1200"></div>
    </template>
    <template v-else>
      <div v-if="!hideHeader" class="widget-header flex justify-between px-2 py-1.5 pl-2.5 text-sm leading-4">
        <div class="flex items-center space-x-2 truncate py-1">
          <span class="truncate font-medium">
            {{ widget.title }}
          </span>
        </div>
      </div>
      <div :style="widgetContentHeight" class="widget-content overflow-auto">
        <template v-if="widgetAccessLevel == 'restricted'">
          <div class="absolute inset-0 z-[100] w-full backdrop-blur-md"></div>
          <img :src="placeholderImage" class="z-10" />
        </template>
        <template v-else>
          <slot name="body"></slot>
        </template>
      </div>
    </template>
  </div>
</template>
<script setup>
import { debounce } from 'lodash';
import { applyRawCSS, applyFontFamily } from '@/composeables//helpers';
import { computed, watch, onMounted } from 'vue';
import TrendingOnTwitter from '@/images/nexo/trendingontwitter.png';
import SectorPerformance from '@/images/nexo/sectorperformance.png';
import ExchangeNetflows from '@/images/nexo/exchangenetflows.png';
import AverageTwitterVolume from '@/images/nexo/averagetwittervolume.png';
import TVL from '@/images/nexo/tvl.png';
import OwnershipHolders from '@/images/nexo/ownershipholders.png';
import OnchainTransactions from '@/images/nexo/onchaintransactions.png';

const props = defineProps({
  widget: { type: Object },
  baseTheme: { type: Object },
  loading: { type: Boolean, default: true },
  widgetAccessLevel: { type: String }
});

// PLACEHOLDER IMAGES
const placeholderImage = computed(() => {
  return {
    NexoWidgetsTrendingOnTwitter: TrendingOnTwitter,
    NexoWidgetsSectorPerformance: SectorPerformance,
    NexoWidgetsExchangeNetFlowChart: ExchangeNetflows,
    NexoWidgetsTwitterVolumeChart: AverageTwitterVolume,
    NexoWidgetsTvlChart: TVL,
    NexoWidgetsOwnershipHolders: OwnershipHolders,
    NexoWidgetsDexTransactions: OnchainTransactions
  }[props.widget.widget_template.template];
});

//WIDGET
const hideHeader = computed(() => {
  return props.baseTheme ? props.baseTheme.settings.hide_header : false;
});
const widgetContentHeight = computed(() => {
  if (hideHeader.value) {
    return { height: '100%' };
  }
  return { height: 'calc(100% - 28px)' };
});

//THEME & CSS
const rawThemeCSS = computed(() => {
  const theme = props.baseTheme ? props.baseTheme.settings.raw : null;
  if (theme != '\n\n\n\n\n\n\n\n\n\n') {
    return theme;
  }
  return null;
});
watch(rawThemeCSS, () => {
  applyBaseTheme();
});
onMounted(() => {
  applyBaseTheme();
});
const applyBaseTheme = debounce(() => {
  if (rawThemeCSS.value) {
    applyRawCSS(rawThemeCSS.value);
  } else {
    applyRawCSS(`.widget-container {}\n.widget-content{height: ${widgetContentHeight.value.height}}\n.widget-header{}`);
  }
}, 100);

//FONTS
const fontFamily = computed(() => {
  return props.baseTheme ? props.baseTheme.settings.font : null;
});
watch(fontFamily, () => {
  applyFonts();
});
const fontFace = computed(() => {
  return props.baseTheme && props.baseTheme.settings.font_face ? true : false;
});
onMounted(() => {
  applyFonts();
});
const applyFonts = debounce(() => {
  if (fontFamily.value) {
    applyFontFamily(fontFamily.value, fontFace.value);
  }
}, 100);
</script>
<style>
.widget-container::after {
  content: '';
  display: block;
  clear: both;
}
</style>
