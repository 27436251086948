<template>
  <div class="h-full" :id="id">
    <NexoWidgetsBase :widget="widget" :base-theme="baseTheme" :loading="false">
      <template #body>
        <NexoTableBase :config="tableConfig" :data="events" :loading="loadingEvents" :paginate="getOldEvents" />
      </template>
    </NexoWidgetsBase>
  </div>
</template>
<script setup>
import { ref, onMounted, computed, inject } from 'vue';
import uniqBy from 'lodash/uniqBy';

const $http = inject('http');

const props = defineProps({
  widget: { type: Object, required: true },
  baseTheme: { type: Object },
  overrides: { type: Object },
  templateId: { type: String },
  tvlCoins: { type: Array },
  widgetAccessLevel: { type: String }
});

//CALENDAR EVENTS
const loadingEvents = ref(false);
const page = ref(0);
const allEvents = ref([]);
const events = computed(() => {
  const mapped = allEvents.value.map(x => {
    return {
      ...x,
      title: x?.title?.en || '',
      description: x?.description?.en || '',
      event_link: {
        link: x?.original_source || '',
        text: 'Link'
      }
    };
  });
  return uniqBy(mapped, 'original_source');
});
async function getEvents(page) {
  const response = await $http.get('/integrations/calendar_events', { params: { page: page } });
  return response?.data?.data || [];
}
async function getNewEvents() {
  const data = await getEvents(1);
  allEvents.value.unshift(data);
}
async function getOldEvents() {
  try {
    loadingEvents.value = true;
    page.value++;
    const data = await getEvents(page.value);
    allEvents.value = [...allEvents.value, data].flat();
  } catch (e) {
    console.log('ERROR:', e);
  } finally {
    loadingEvents.value = false;
  }
}
onMounted(() => {
  getOldEvents();
  //REFRESH EVERY 5 MINS
  setTimeout(() => {
    getNewEvents();
  }, 60000 * 5);
});

//TABLE CONFIG
const tableConfig = computed(() => {
  return {
    cols: [
      { type: 'date', id: 'date_event', name: 'Date' },
      { type: 'stripped_text', id: 'title', name: 'title', sortable: false },
      { type: 'stripped_text', id: 'description', name: 'Description', sortable: false },
      { type: 'link_icon', id: 'original_source', name: 'Source', sortable: false, headerClass: 'justify-end' }
    ],
    headerClass: 'py-8 sticky top-0 bg-skin-fill z-30 capitalize',
    horizontalScroll: true,
    sortable: false,
    rowClass: 'py-2 duration-100',
    perPage: 50
  };
});
</script>
