<template>
  <div class="h-full">
    <NexoWidgetsBase :widget="widget" :base-theme="baseTheme" :loading="loading">
      <template #body>
        <template v-if="filteredCoins.length">
          <div class="flex h-full w-full items-end justify-between space-x-6 p-6">
            <div
              v-for="coin in filteredCoins"
              :key="coin.coin_uid"
              @click="handleCoinClick(coin)"
              class="group flex cursor-pointer flex-col items-center"
            >
              <div :class="[barColor.text, 'mb-2 text-xs group-hover:font-bold']">
                {{ getPretext(coin) }}
              </div>
              <div class="flex h-32 flex-1">
                <div
                  :style="`height: ${playAnimation ? getBarHeight(coin[datapoint]) : 0}px`"
                  :class="[
                    barColor.outline,
                    'w-[70px] transform rounded-tl rounded-tr bg-gradient-to-b p-px transition-[height] duration-1000 ease-in-out'
                  ]"
                >
                  <div class="bg-skin-fill h-full w-full rounded-tl rounded-tr">
                    <div
                      :class="[barColor.fill, barColor.hover, 'h-full w-full rounded-tl rounded-tr bg-gradient-to-b']"
                    ></div>
                  </div>
                </div>
              </div>
              <div class="-mt-4 flex h-20 flex-col items-center">
                <div class="flex flex-col items-center">
                  <img
                    :src="`https://terminal.cloudfront.thetie.io/coin_images/${coin.coin_uid}.png`"
                    class="z-10 h-6 w-6 rounded-full shadow-lg"
                  />
                  <span class="block text-sm font-medium text-gray-100">{{ coin.ticker }}</span>
                  <span
                    v-if="column == 'defi'"
                    class="block text-xs font-medium capitalize text-gray-100"
                    :class="coin.ticker + '_price'"
                  >
                    {{ coin.name.split(' ')[0] }}
                  </span>
                </div>
                <span
                  v-if="column != 'defi'"
                  class="block text-xs font-medium text-gray-100"
                  :class="coin.ticker + '_price'"
                >
                  {{ coin.price ? usePriceFormatter(coin.price) : '-' }}
                </span>
                <span
                  :class="[
                    coin.price_return_24_hours > 0 ? 'text-green-500' : 'text-red-500',
                    'block text-xs font-medium'
                  ]"
                >
                  {{
                    coin.price_return_24_hours
                      ? usePercentFormatter(useRoundedNumber(coin.price_return_24_hours * 100))
                      : '-'
                  }}
                </span>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <NexoNoDataError />
        </template>
      </template>
    </NexoWidgetsBase>
  </div>
</template>
<script setup>
import { handleCoinClick } from '@/composeables/integrations/nexo/helpers';
import { min, max } from 'lodash';
import { onMounted, ref, computed, onBeforeMount } from 'vue';
import { useStore } from 'vuex';
import {
  usePriceFormatter,
  useBigNumberFormatter,
  usePercentFormatter,
  useRoundedNumber
} from '@/composeables/integrations/nexo/helpers';

const $store = useStore();
const props = defineProps({
  widget: { type: Object, required: true },
  baseTheme: { type: Object },
  overrides: { type: Object },
  templateId: { type: String }
});

//ANIMATION
const playAnimation = ref(false);
onMounted(() => {
  setTimeout(() => {
    playAnimation.value = true;
  }, 1000);
});

//BAR
const color = ref('green');
onBeforeMount(() => {
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has('color')) {
    color.value = urlParams.get('color');
  }
});
const barColor = computed(() => {
  return {
    green: {
      text: 'text-green-500',
      outline: 'from-green-500',
      fill: 'from-green-900/30',
      hover: 'group-hover:from-green-900/50'
    },
    red: {
      text: 'text-red-500',
      outline: 'from-red-500',
      fill: 'from-red-900/30',
      hover: 'group-hover:from-red-900/50'
    },
    blue: {
      text: 'text-gray-100',
      outline: 'from-blue-600',
      fill: 'from-blue-900/30',
      hover: 'group-hover:from-blue-900/50'
    }
  }[color.value];
});

function getBarHeight(value) {
  //h-32
  const maxBarHeight = 128;
  const margin = maxBarHeight * 0.2;
  const percentage = (value - minDatapointValue.value) / (maxDatapointValue.value - minDatapointValue.value);
  return maxBarHeight * percentage + margin;
}

//Defi COINS
const categories = computed(() => {
  return $store.state.categories?.coin || [];
});

const defiCoins = computed(() => {
  if (categories.value.length > 0) {
    return categories.value.find(x => x.name == 'Decentralized Finance (DeFi)')?.flat_coin_uids || [];
  }
  return [];
});

//ASSETS
const column = ref('sentiment');
const order = ref('desc');
onBeforeMount(() => {
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has('column')) {
    column.value = urlParams.get('column').toLowerCase();
  }
  if (urlParams.has('order')) {
    order.value = urlParams.get('order').toLowerCase();
  }
});
const datapoint = computed(() => {
  return {
    sentiment: 'daily_sentiment',
    defi: 'tvl'
  }[column.value];
});

const filteredCoins = computed(() => {
  const allCoins = $store.getters.coins;
  if (allCoins.length) {
    let coins = [...allCoins];
    if (column.value == 'defi') {
      coins = coins.filter(x => defiCoins.value.includes(x.coin_uid));
    }
    return applySort(coins, datapoint.value, order.value == 'asc').slice(0, 5);
  }
  return [];
});
const selectedDatapointValues = computed(() => {
  return filteredCoins.value.map(x => {
    return parseFloat(x[datapoint.value]);
  });
});
const maxDatapointValue = computed(() => {
  return max(selectedDatapointValues.value);
});
const minDatapointValue = computed(() => {
  return min(selectedDatapointValues.value);
});
function applySort(arr, key, ascending = true) {
  var multi = ascending ? 1 : -1;
  var sorter = function (a, b) {
    var aVal = a[key];
    var bVal = b[key];
    if (aVal === null && bVal === null) return 0;
    else if (aVal === null) return 1;
    else if (bVal === null) return -1;

    return parseFloat(aVal) > parseFloat(bVal) ? 1 * multi : -1 * multi;
  };

  return arr.filter(x => key in x && x[key] !== null).sort(sorter);
}

//SUBTEXT & PRETEXT
function getPretext(coin) {
  if (column.value == 'defi') {
    return useBigNumberFormatter(coin.tvl, 2, '$');
  } else {
    return Math.trunc(coin[datapoint.value]);
  }
}

const loading = computed(() => {
  return $store.getters.coinsLoader;
});
</script>
