<template>
  <div class="h-full">
    <NexoWidgetsBase
      :widget="widget"
      :base-theme="baseTheme"
      :loading="loading"
      :widget-access-level="widgetAccessLevel"
    >
      <template #body>
        <template v-if="noDataFound">
          <NexoNoDataError />
        </template>
        <template v-else>
          <NexoTableOnChainTransactions
            :transactions="transactions"
            :prices="prices"
            :current-coin-price="currentCoin.price"
          />
        </template>
      </template>
    </NexoWidgetsBase>
  </div>
</template>

<script setup>
import { useStore } from 'vuex';
import { onMounted, ref, computed, inject, watch } from 'vue';

const $http = inject('http');
const $store = useStore();

const props = defineProps({
  widget: { type: Object, required: true },
  baseTheme: { type: Object },
  overrides: { type: Object },
  templateId: { type: String },
  widgetAccessLevel: { type: String }
});

//COIN
const coinTicker = ref(null);
const coins = computed(() => {
  return $store.getters.coins;
});
const currentCoin = computed(() => {
  return coins.value.find(x => x.ticker.toLowerCase() == coinTicker.value) || { price: 0 };
});
const chain = ref('ethereum');
onMounted(() => {
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has('coin')) {
    coinTicker.value = urlParams.get('coin').toLowerCase();
    getTransactions();
  } else {
    noDataFound.value = true;
  }
  if (urlParams.has('chain')) {
    chain.value = urlParams.get('chain').toLowerCase();
  }
});

//TRANSACTIONS AND PRICES
const loading = ref(false);
const noDataFound = ref(false);
const transactions = ref([]);
const prices = ref([]);
async function getTransactions() {
  loading.value = true;
  try {
    let response = await $http.get('/integrations/onchain_transactions', {
      params: {
        coin: coinTicker.value
      }
    });

    if (!response.data) {
      noDataFound.value = true;
    } else {
      transactions.value =
        response.data?.transactions.sort((a, b) => new Date(b.block_timestamp) - new Date(a.block_timestamp)) || [];
      prices.value = response.data?.prices || [];
    }
  } catch (e) {
    noDataFound.value = true;
  } finally {
    loading.value = false;
  }
}
</script>
