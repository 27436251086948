<template>
  <div class="h-full">
    <NexoWidgetsBase
      :widget="widget"
      :base-theme="baseTheme"
      :loading="chartLoading"
      :widget-access-level="widgetAccessLevel"
    >
      <template #body>
        <template v-if="!coinTicker || chartError">
          <NexoNoDataError />
        </template>
        <template v-else>
          <div class="h-full w-full py-4 px-6">
            <!-- <div class="flex justify-end px-4 pb-2">
              <NexoTags :tags="timeIntervals" v-model="selectedInterval" @update:model-value="updateChartSeries" />
            </div> -->
            <Chart class="h-full w-full" :options="chartOptions" />
          </div>
        </template>
      </template>
    </NexoWidgetsBase>
  </div>
</template>
<script setup>
import { useStore } from 'vuex';
import { ref, onBeforeMount, watch, inject, computed } from 'vue';
import { useRoundedNumber, usePriceFormatter } from '@/composeables/integrations/nexo/helpers';
import moment from 'moment';
import { Chart } from 'highcharts-vue';
import colors from 'tailwindcss/colors';

const $http = inject('http');
const $store = useStore();
const props = defineProps({
  widget: { type: Object, required: true },
  baseTheme: { type: Object },
  overrides: { type: Object },
  templateId: { type: String },
  widgetAccessLevel: { type: String }
});

//INTERVALS
const selectedInterval = ref(1);
const timeIntervals = [
  { id: 0, days: 7, label: '1H' },
  { id: 1, days: 30, label: '1D' }
];

//COIN
const coinTicker = ref(null);

onBeforeMount(() => {
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has('coin')) {
    coinTicker.value = urlParams.get('coin').toLowerCase();
    loadExchangeNetflow();
  }
});

//COIN PRICE DATA
const priceData = ref([]);
const priceSeries = computed(() => {
  return priceData.value.map(x => {
    return [new Date(x.datetime).getTime(), parseFloat(x.price)];
  });
});

//NETFLOW DATA
const netflowSeries = computed(() => {
  return netflowData.value.map(([x, y]) => {
    return [new Date(x).getTime(), y];
  });
});
const netflowData = ref([]);
async function loadExchangeNetflow() {
  if (coinTicker.value) {
    try {
      chartError.value = false;
      const response = await $http.get('/integrations/exchanges_supply', {
        params: {
          coin: coinTicker.value
        }
      });

      netflowData.value = response?.data?.flows?.netflow || [];
      priceData.value = response?.data?.prices || [];

      //no reason to plot price if netflows are null
      if (netflowData.value.length == 0) {
        chartError.value = true;
      }
    } catch {
      chartError.value = true;
    } finally {
      chartLoading.value = false;
    }
  } else {
    chartLoading.value = false;
  }
}

//CHART CONFIG
const chartLoading = ref(true);
const chartError = ref(false);
const chartOptions = computed(() => {
  return {
    tooltip: {
      shared: true,
      pointFormatter: function () {
        const roundedValue = useRoundedNumber(this.y, 6);
        if (this.series.name.includes('Price')) {
          return usePriceFormatter(roundedValue);
        }
        return roundedValue + '%';
      }
    },
    yAxis: [
      { title: { enabled: true, text: 'Net Flow' }, opposite: false },
      { title: { enabled: true, text: 'Price' }, opposite: true }
    ],
    series: chartSeries.value,
    plotOptions: {
      column: {
        borderWidth: 0
      }
    }
  };
});

const chartSeries = computed(() => {
  let series = [];
  if (netflowSeries.value.length > 0) {
    series.push({
      name: 'Netflow',
      type: 'column',
      data: netflowSeries.value,
      color: colors.green[500],
      negativeColor: colors.red[500]
    });
  }
  if (netflowData.value.length > 0) {
    series.push({
      name: 'Price',
      type: 'line',
      data: priceSeries.value,
      color: colors.blue[500],
      yAxis: 1
    });
  }
  return series;
});
</script>
