<template>
  <div class="h-full">
    <NexoWidgetsBase :widget="widget" :base-theme="baseTheme" :loading="dataLoading">
      <template #body>
        <div class="flex items-center justify-between p-6">
          <NexoTags
            :tags="[
              { id: 'all', label: 'All' },
              { id: 'trending', label: 'Trending' }
            ]"
            v-model="selectedTab"
          />
        </div>
        <NexoButton
          v-if="showScrollToTop"
          @press="scrollToTop(scroller)"
          size="sm"
          class="btn-gototop bg-skin-btn-primary-accent absolute bottom-12 right-4 z-30"
        >
          <IconArrowUp />
        </NexoButton>
        <VirtualList
          v-if="news.length"
          class="mb-4 px-6"
          ref="scroller"
          :id="`news-widget-${Math.random()}`"
          :style="`height: calc(100% - ${height}px); overflow-y: auto`"
          :data-key="'link_hash'"
          :data-sources="news"
          :data-component="component"
          @tobottom="getOlderNews(newsParams)"
        >
        </VirtualList>
        <div v-else-if="!loading" :style="`height: calc(100% - ${height}px); overflow-y: auto`">
          <NexoNoDataError />
        </div>
      </template>
    </NexoWidgetsBase>
  </div>
</template>

<script setup>
import VirtualList from 'vue3-virtual-scroll-list';
import useNewsMixin from '@/composeables/integrations/nexo/news';
import NexoSimpleNewsFeedItem from '../NexoSimpleNewsFeedItem.vue';
import { onMounted, onBeforeUnmount, computed, watch, ref, inject } from 'vue';
import { useStore } from 'vuex';
const $store = useStore();
const $http = inject('http');

const {
  showScrollToTop,
  latestTimestamp,
  news,
  pollingInterval,
  endTimestamp,
  loading,
  scrollToTop,
  getOlderNews,
  getNewsFromCache
} = useNewsMixin();

const props = defineProps({
  widget: { type: Object, required: true },
  baseTheme: { type: Object },
  overrides: { type: Object },
  templateId: { type: String }
});

const component = ref(NexoSimpleNewsFeedItem);
const scroller = ref(null);
const dataLoading = ref(true);
onMounted(async () => {
  getTags();
  await getNewsFromCache(cacheNewsParams.value);
  dataLoading.value = false;
  pollingInterval.value = setInterval(() => {
    getNewsFromCache(cacheNewsParams.value);
  }, 60000);
});

//TABS SELECTION
const cacheNewsParams = computed(() => {
  return {
    load_from_cache: true,
    cache_key: selectedTab.value == 'trending' ? 'nexo_trending_news' : 'nexo_news'
  };
});
const selectedTab = ref('all');
watch(selectedTab, () => {
  resetAndFetchNews();
});
function resetAndFetchNews() {
  latestTimestamp.value = null;
  endTimestamp.value = null;
  news.value = [];
  loading.value = true;
  getNewsFromCache(cacheNewsParams.value);
}

//NEWS TAGS
const newsTags = ref({});
async function getTags() {
  let response = await $http.get('/integrations/news_tags');
  newsTags.value = response.data;
}

//ALLOWED COLLECTIONS
const allowedCollections = ref(['Crypto Native Publications', 'Traditional Finance', 'Mainstream Media']);
const allowedCollectionsIds = computed(() => {
  return newsTags.value.collections?.filter(x => allowedCollections.value.includes(x.name)).map(x => x.id) || [];
});

//NEWS PARAMETERS
const newsParams = computed(() => {
  return {
    is_trending_news: selectedTab.value == 'trending' ? true : false,
    collections: allowedCollectionsIds.value
  };
});

//NEWS COMPONENT HEIGHT
const height = ref(0);
onMounted(() => {
  height.value = props.baseTheme && props.baseTheme.settings.hide_header ? 86 : 86;
});

onBeforeUnmount(() => {
  clearInterval(pollingInterval.value);
});

watch(props.widget.configuration, () => {
  resetAndFetchNews();
});
</script>
