<template>
  <div class="news-item h-auto pb-4" :class="{ latest: recentNewsItem }">
    <div class="relative">
      <div :class="coinNews && widgetWidth > 700 ? 'flex justify-between' : 'flex-1'">
        <a :href="item.link" target="_blank" class="news-headline">
          <p
            class="text-skin-base mt-0.5 text-sm font-medium leading-5"
            :class="coinNews && widgetWidth > 700 ? 'line-clamp-1' : 'line-clamp-2'"
            v-html="item.translated_headline || item.headline"
          />
        </a>
        <div v-if="coinNews" class="news-source text-skin-inverted flex items-center space-x-2 text-xs">
          <span class="text-skin-muted block whitespace-nowrap text-xs">
            {{ relativeTime(item.timestamp) }}
          </span>
          <div class="bg-skin-fill-bullet mr-2 rounded-full p-0.5"></div>
          <span
            class="whitespace-nowrap"
            :title="item.sources.length > 1 ? item.sources.map(o => o.name).join(', ') : ''"
          >
            {{ sourceName }}
          </span>
        </div>
        <div v-else class="news-source text-skin-inverted flex flex-wrap items-center space-x-2 text-xs">
          <span :title="item.sources.length > 1 ? item.sources.map(o => o.name).join(', ') : ''">
            {{ sourceName }}
          </span>
          <div class="bg-skin-fill-bullet mr-2 rounded-full p-0.5"></div>
          <span class="text-skin-muted block whitespace-nowrap text-xs">
            {{ relativeTime(item.timestamp) }}
          </span>
          <div v-for="tag in orderedTags" :key="tag.id">
            <span class="flex items-center font-medium">
              <div class="bg-skin-fill-bullet mr-2 rounded-full p-0.5"></div>
              <span class="text-xs font-normal">
                {{ tag.name }}
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import moment from 'moment';
import { onBeforeMount, ref, computed } from 'vue';
import { relativeTime } from '@/composeables//filters';

const item = ref({});

const props = defineProps({
  index: { type: Number },
  coinNews: { type: Boolean, default: false },
  widgetWidth: { type: Number, default: 0 },
  source: { type: Object, default: () => {} }
});

onBeforeMount(() => {
  item.value = props.source;
});

const sourceName = computed(() => {
  return item.value.sources.length > 1
    ? `${item.value.sources[0]?.name} and ${item.value.sources.length - 1} more...`
    : item.value.sources[0]?.name;
});
const recentNewsItem = computed(() => {
  var now = moment(new Date()); //todays date
  var duration = moment.duration(now.diff(item.value.timestamp));
  var minutes = duration.asMinutes();
  return minutes < 5 ? true : false;
});
const orderedTags = computed(() => {
  let res = [];

  const addSubgroup = subgroup => {
    (item.value.tags || []).forEach(tag => {
      if ((tag.tag_subgroup || {}).name === subgroup) {
        res.push(tag);
      }
    });
  };

  addSubgroup('Coins');

  res = res.filter(x => x.tag_subgroup.name == 'Coins');
  return res;
});
</script>
