import moment from 'moment';
import { DateTime } from 'luxon';

export default {
  methods: {
    percentage(val) {
      if (typeof val === 'undefined' || val === null || val === '' || val === 'N/A') {
        return '';
      }
      return parseFloat(val) > 0 ? `+${val}%` : `${val}%`;
    },

    decimals(value, minDecimals = 4, maxDecimals = 4) {
      if (typeof value === 'undefined' || value === null || value === '' || isNaN(value)) {
        return '';
      }
      return new Intl.NumberFormat('en-US', {
        minimumFractionDigits: minDecimals,
        maximumFractionDigits: maxDecimals
      }).format(value);
    },

    roundSmallPercent(value, minDecimals = 2, maxDecimals = 4) {
      if (typeof value === 'undefined' || value === null || value === '') {
        return '';
      }
      if (value < 0.01) {
        minDecimals = 4;
        maxDecimals = 4;
      }
      return new Intl.NumberFormat('en-US', {
        minimumFractionDigits: minDecimals,
        maximumFractionDigits: maxDecimals
      }).format(value);
    },

    rounded(value, decimalPlaces = 2) {
      if (typeof value === 'undefined' || value === null || value === '' || isNaN(value)) {
        return '';
      }
      var decimalNumber = typeof value === 'string' ? parseFloat(value) : value;

      return new Intl.NumberFormat('en-US', {
        minimumFractionDigits: decimalPlaces,
        maximumFractionDigits: decimalPlaces
      }).format(decimalNumber);
    },

    roundedPercent(value, decimalPlaces = 2) {
      const r = this.rounded(value, decimalPlaces);
      return r ? r + '%' : '';
    },

    usdCryptoPrice(value) {
      if (typeof value === 'undefined' || value === null || value === '') {
        return '';
      }
      var decimals = Math.abs(value) < 1 ? 6 : 2;
      var prefix = value < 0 ? '-' : '';
      value = value < 0 ? value * -1 : value;
      return (
        prefix +
        '$' +
        new Intl.NumberFormat('en-US', {
          minimumFractionDigits: decimals,
          maximumFractionDigits: decimals
        }).format(value)
      );
    },

    btcCryptoPrice(value) {
      if (typeof value === 'undefined' || value === null || value === '') {
        return '';
      }
      var inSats = false;
      if (value <= 0.0001) {
        inSats = true;
        value = value * 100000000;
      }
      var rounded = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 6,
        maximumFractionDigits: 6
      }).format(value);

      return inSats ? `${rounded} sats` : `${rounded} BTC`;
    },

    truncate(value, maxChars) {
      if (value && value.length > maxChars) {
        return value.slice(0, maxChars) + '...';
      }
      return value;
    },

    // NumericalDate YYYY-MM-DD
    numericalDate(date) {
      if (!date) {
        return '';
      }
      return moment.utc(date).format('YYYY-MM-DD');
    },

    readableDate(date) {
      if (!date) {
        return '';
      }
      return moment(date).format('MMM D, YYYY');
    },

    readableDateTime(date) {
      if (!date) {
        return '';
      }
      return moment(date).format('LLLL');
    },

    timeWithinToday(date) {
      return moment(date).format('YYYY-MM-DD h:mma');
    },

    minimalTimeWithinToday(date) {
      //return empty if null
      if (!date) {
        return '';
      }
      // if today then remove YYYY-MM-DD
      if (moment(date).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) {
        return moment(date).format('h:mma');
      }

      // if same year, remove the year
      if (moment(date).format('YYYY') === moment().format('YYYY')) {
        return moment(date).format('MMM D, h:mma');
      }

      return moment(date).format('lll');
    },

    relativeTime(date) {
      return moment.utc(date).fromNow();
    },

    formatPrice(num) {
      const dec = num > 1 ? 100 : 1000000;
      return (Math.round(Number(num) * dec) / dec).toLocaleString(undefined, {
        minimumFractionDigits: num > 1 ? 2 : 6
      });
    },

    formatNumber(num, digits = 2, prefix = '') {
      if (typeof num === 'undefined' || num === null || num === '' || num == 0 || num == 0.0) {
        return '';
      }
      var si = [
        { value: 1, symbol: '' },
        { value: 1e3, symbol: 'k' },
        { value: 1e6, symbol: 'M' },
        { value: 1e9, symbol: 'B' },
        { value: 1e12, symbol: 'T' },
        { value: 1e15, symbol: 'P' },
        { value: 1e18, symbol: 'E' }
      ];
      var negative = [
        { value: -1, symbol: '' },
        { value: -1e3, symbol: 'k' },
        { value: -1e6, symbol: 'M' },
        { value: -1e9, symbol: 'B' },
        { value: -1e12, symbol: 'T' },
        { value: -1e15, symbol: 'P' },
        { value: -1e18, symbol: 'E' }
      ];
      var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
      var i;
      if (num >= 0) {
        for (i = si.length - 1; i > 0; i--) {
          if (num >= si[i].value) {
            break;
          }
        }
      } else {
        for (i = si.length - 1; i > 0; i--) {
          if (num <= negative[i].value) {
            break;
          }
        }
      }
      return prefix + (num / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol;
    },

    slugify(str, separator = '-') {
      return str
        .trim()
        .toLowerCase()
        .replace(/[^\w ]+/g, '')
        .replace(/ +/g, separator);
    },

    roundedBtc(value, decimalPlaces = 2) {
      if (typeof value === 'undefined' || value === null || value === '' || isNaN(value)) {
        return '';
      }
      var decimalNumber = typeof value === 'string' ? parseFloat(value) : value;

      if (value < 1) {
        decimalPlaces = 6;
      }
      return new Intl.NumberFormat('en-US', {
        minimumFractionDigits: decimalPlaces,
        maximumFractionDigits: decimalPlaces
      }).format(decimalNumber);
    },

    titleize(str, separator = '-') {
      return str
        .split(separator)
        .map(word => {
          return word.charAt(0).toUpperCase() + word.substring(1).toLowerCase();
        })
        .join(' ');
    },

    upperCase(str, separator = '-') {
      return str
        .split(separator)
        .map(word => {
          return word.toUpperCase();
        })
        .join(' ');
    },
    toChatTime(timestamp, format = '') {
      let datetime = '';
      if (typeof timestamp === 'string') {
        datetime = DateTime.fromISO(timestamp);
      } else {
        datetime = DateTime.fromJSDate(timestamp);
      }
      if (format == 'channel') {
        if (datetime > DateTime.now().startOf('day')) {
          return datetime.toLocaleString(DateTime.TIME_SIMPLE) + ' today';
        } else {
          return datetime.toFormat('h:mm a') + ' on ' + datetime.toFormat('MMMM dd, yyyy');
        }
      } else if (format == 'chat_start') {
        if (datetime > DateTime.now().startOf('day')) {
          return 'Today';
        } else {
          return datetime.toFormat('MMMM dd, yyyy');
        }
      } else if (datetime > DateTime.now().startOf('day')) {
        if (format === 'simple') {
          return datetime.toLocaleString(DateTime.TIME_SIMPLE);
        } else {
          return 'Today at ' + datetime.toLocaleString(DateTime.TIME_SIMPLE);
        }
      } else {
        return datetime.toFormat('MM/dd/yyyy');
      }
    }
  }
};
