<template>
  <div class="h-full">
    <NexoWidgetsBase
      :widget="widget"
      :base-theme="baseTheme"
      :loading="loading"
      :widget-access-level="widgetAccessLevel"
    >
      <template #body>
        <template v-if="!coinTicker || chartError">
          <NexoNoDataError />
        </template>
        <template v-else>
          <div class="h-full w-full py-4 px-6">
            <Chart class="h-full w-full" :options="chartOptions" />
          </div>
        </template>
      </template>
    </NexoWidgetsBase>
  </div>
</template>
<script setup>
import { useStore } from 'vuex';
import moment from 'moment';
import colors from 'tailwindcss/colors';
import { Chart } from 'highcharts-vue';
import { ref, onBeforeMount, watch, inject, computed } from 'vue';
import { useBigNumberFormatter } from '@/composeables/integrations/nexo/helpers';

const $http = inject('http');
const $store = useStore();

const props = defineProps({
  widget: { type: Object, required: true },
  baseTheme: { type: Object },
  overrides: { type: Object },
  templateId: { type: String },
  widgetAccessLevel: { type: String }
});

//COIN
const coinTicker = ref(null);
onBeforeMount(() => {
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has('coin')) {
    coinTicker.value = urlParams.get('coin').toLowerCase();
    loadChartData();
  }
});

//CHART
const loading = ref(true);
const chartError = ref(false);
const chartOptions = computed(() => {
  return {
    chart: {
      type: 'area',
      events: {
        load: adjustGradient,
        redraw: adjustGradient
      }
    },
    tooltip: {
      pointFormatter: function () {
        return useBigNumberFormatter(this.y);
      }
    },
    series: [
      {
        name: 'Avg. Twitter Volume',
        data: chartSeries.value,
        color: colors.blue[500],
        fillColor: {
          linearGradient: [0, 0, 0, 0],
          stops: [
            [0, 'rgba(30, 58, 138, 0.6)'],
            [0.3, 'rgba(30, 58, 138, 0.3)'],
            [0.6, 'rgba(30, 58, 138, 0.1)'],
            [1, 'rgba(22, 24, 29, 0)']
          ]
        }
      }
    ]
  };
});
const chartData = ref([]);
const chartSeries = computed(() => {
  return chartData.value.map(x => {
    return [new Date(x.datetime).getTime(), parseFloat(x.tweet_volume_30_day_avg)];
  });
});
const chartPayload = computed(() => {
  return {
    load_from_cache: true,
    coin_uids: [coinTicker.value],
    items: 'tweet_volume_30_day_avg',
    frequency: '1day'
  };
});
async function loadChartData() {
  if (coinTicker.value) {
    try {
      chartError.value = false;
      let response = await $http.post('/integrations/coins/historical_grouped', chartPayload.value);
      if (!response.data || !response.data.data) {
        chartError.value = true;
      } else {
        chartData.value = response.data.data;
      }
    } catch {
      chartError.value = true;
    } finally {
      loading.value = false;
    }
  }
}

function adjustGradient() {
  document.getElementsByTagName('linearGradient')[0].setAttributeNS(null, 'y2', this.plotHeight);
}
</script>
