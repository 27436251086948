<template>
  <div class="h-full" :id="id">
    <NexoWidgetsBase :widget="widget" :base-theme="baseTheme" :loading="loading || coinsLoader">
      <template #body>
        <div v-for="(trig, index) in alertData.metrics" :key="index" class="space-y-6 p-4 px-6 text-sm font-medium">
          <div class="border-skin-fill flex w-full items-center justify-between border py-1 pr-2">
            <NexoDropdownSimple
              class="w-full text-xs"
              container-class="bg-skin-fill pl-2 pr-5 justify-start"
              :active-item="selectedCoin.id"
              :items="coinsDropdown"
              :truncate-value="50"
              @selected="selectCoin"
            ></NexoDropdownSimple>
            <div v-if="selectedCoin.id" class="whitespace-nowrap">
              <span class="mr-1">{{ metricList[selectedMetric].formatter(metricPrimaryValue) }}</span>
              <span :class="metricPercentChange >= 0 ? 'text-green-500' : 'text-red-500'">
                {{ metricValueChange }}
                ({{
                  useRoundedNumber(
                    metricPercentChange > 0 ? metricPercentChange * 100 : metricPercentChange * 100 * -1
                  )
                }}%)
              </span>
            </div>
          </div>
          <div class="flex flex-col">
            <label class="text-skin-muted mb-2 text-xs"> Metric </label>
            <div v-for="metric in metricOptions" :key="metric.id" class="mb-4 flex">
              <input :id="metric.id" type="radio" v-model="trig.mainMetric" :value="metric.id" class="hidden" />
              <label
                :for="metric.id"
                class="flex cursor-pointer items-center text-xs font-medium"
                :class="selectedMetric == metric.id ? 'text-skin-base' : 'text-skin-muted'"
              >
                <div
                  class="flex-no-shrink mr-2 flex h-4 w-4 items-center justify-center rounded-full border"
                  :class="selectedMetric == metric.id ? 'border-blue-500' : 'border-gray-600'"
                >
                  <div v-if="selectedMetric == metric.id" class="h-2 w-2 rounded-full bg-blue-500 pl-0.5"></div>
                </div>
                {{ metric.title }}
              </label>
            </div>
          </div>
          <div class="text-skin-inverted space-y-2 text-xs">
            <div>Direction</div>
            <NexoTags
              class="w-full"
              :wrap-tags="false"
              tag-class="w-1/2 text-center py-2.5 flex items-center justify-center text-skin-base"
              :tags="[
                { id: 'up', label: 'Up', icon: 'IconArrowUp', iconClass: 'rotate-45 mr-1' },
                { id: 'down', label: 'Down', icon: 'IconArrowDown', iconClass: '-rotate-45 mr-1' }
              ]"
              v-model="trig.comparison"
            ></NexoTags>
          </div>
          <div v-if="thresholdOptions.length" class="text-skin-inverted space-y-2 text-xs">
            <div>Threshold</div>
            <NexoSlider :key="selectedMetric" @selected="trig.value = $event" :options="thresholdOptions"></NexoSlider>
          </div>
          <div v-if="rangeOptions.length" class="text-skin-inverted space-y-2 text-xs">
            <div>Over</div>
            <NexoSlider :key="selectedMetric" @selected="selectedRange = $event" :options="rangeOptions"></NexoSlider>
          </div>
          <div class="flex space-x-2">
            <NexoButton size="md" class="w-1/2" :disabled="!validAlertConfig || buttonDisabled" @press="createAlert">
              Create Alarm
            </NexoButton>
            <NexoButton
              size="md"
              type="secondary"
              :disabled="buttonDisabled"
              @press="reset"
              style="min-height: 40px"
              class="bg-skin-btn-secondary-accent w-1/2"
            >
              Cancel
            </NexoButton>
          </div>
        </div>
      </template>
    </NexoWidgetsBase>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch, inject } from 'vue';
import { useBigNumberFormatter, useNumberFormatter, useRoundedNumber } from '@/composeables/integrations/nexo/helpers';
import { useStore } from 'vuex';
import { groupBy } from 'lodash';
const store = useStore();
const http = inject('http');

const props = defineProps({
  widget: { type: Object, required: true },
  baseTheme: { type: Object },
  overrides: { type: Object },
  templateId: { type: String }
});

//ALERT TEMPLATES
const loading = ref(true);
const alertTemplates = ref([]);
onMounted(() => {
  getAlertTemplates();
});
async function getAlertTemplates() {
  let response = await http.get('/integrations/alerts');
  alertTemplates.value = response.data.templates;
  loading.value = false;
}

//ALERT DATA OBJECT
const buttonDisabled = ref(false);
const alertData = ref({
  coins: [],
  name: null,
  metrics: [{ mainMetric: 'price_change', id: null, comparison: null, value: null, type: null, required: false }]
});
const alertName = computed(() => {
  let metric = metricOptions.value.find(x => x.id == selectedMetric.value);
  return metric.name;
});
const validAlertConfig = computed(() => {
  let validate = true;
  if (!alertData.value.name) {
    validate = false;
  }
  alertData.value.metrics.forEach((m, i) => {
    if ([m.comparison, m.value, m.id].some(a => a == null)) {
      validate = false;
    }
  });
  return validate;
});
async function createAlert() {
  try {
    buttonDisabled.value = true;
    let alert = {
      name: alertName.value,
      entity: 'Coin',
      category: 'basic',
      config: JSON.parse(JSON.stringify(alertData.value))
    };
    alert.config.metrics = alert.config.metrics.map(({ required, mainMetric, ...keepAttrs }) => keepAttrs);
    await http.post('/integrations/alerts', alert);
    buttonDisabled.value = false;
    reset();
  } catch (e) {
    buttonDisabled.value = false;
  }
}
function reset() {
  selectedCoin.value = {};
  alertData.value = {
    coins: [],
    name: null,
    metrics: [{ mainMetric: '', id: null, comparison: null, value: null, type: null, required: false }]
  };
  selectedRange.value = null;
}

//METRICS
const metricOptions = ref([
  { id: 'price_change', title: 'Price Change', name: 'Price Movement Alert' },
  { id: 'relative_tweet_volume', title: 'Social Volume', name: 'Social Volume Alert' },
  { id: 'relative_trade_volume', title: 'Trading Volume', name: 'Trading Volume Alert' },
  { id: 'num_active_addrs_24hr_change_pct', title: 'On-Chain Activity', name: 'On-Chain Activity Alert' }
]);
const selectedMetric = computed(() => {
  return alertData.value.metrics[0].mainMetric;
});

//SELECTED THRESHOLD
const thresholdOptions = computed(() => {
  return datapointsForCreateAlert.value[selectedMetric.value]
    ? datapointsForCreateAlert.value[selectedMetric.value][0].templates
    : [];
});

//SELECTED RANGE
const selectedRange = ref(null);
const rangeOptions = computed(() => {
  let selectedDatapoint = datapointsForCreateAlert.value[selectedMetric.value];
  return selectedDatapoint && selectedDatapoint.length > 1
    ? selectedDatapoint.map(x => {
        return {
          id: x.id,
          title: x.range,
          value: x.value
        };
      })
    : [];
});

//ALERT METRIC DATAPOINTS
const datapoints = computed(() => {
  return store.getters.coinDatapoints;
});
const datapointsForCreateAlert = computed(() => {
  let datapoints = [];
  alertTemplates.value.forEach(a => {
    a.settings.datapoints.forEach(dp => {
      let x = { ...dp, templates: [] };
      if (dp.id.includes('price_return')) {
        x.category = 'price_change';
      } else if (dp.id.includes('relative_tweet_volume')) {
        x.category = 'relative_tweet_volume';
      } else if (dp.id.includes('relative_trade_volume')) {
        x.category = 'relative_trade_volume';
      } else if (dp.name.includes('num_active_addrs')) {
        x.category = 'onchain';
      }

      a.settings.templates.forEach(t => {
        x.templates.push({ id: t, value: parseInt(t), title: getMetricWithSymbol(t, dp.type) });
      });

      if (dp.name.includes('1hr')) {
        x.range = '1h';
        x.value = 1;
      } else if (dp.name.includes('24hr')) {
        x.range = '24h';
        x.value = 24;
      } else if (dp.name.includes('1d')) {
        x.range = '1d';
        x.value = 24 * 1;
      } else if (dp.name.includes('7d')) {
        x.range = '7d';
        x.value = 24 * 7;
      } else if (dp.name.includes('30d')) {
        x.range = '30d';
        x.value = 24 * 30;
      }
      datapoints.push(x);
    });
  });
  return groupBy(datapoints, 'category');
});
const selectedMetricDatapoint = computed(() => {
  if (!datapointsForCreateAlert.value[selectedMetric.value]) {
    return null;
  }
  if (datapointsForCreateAlert.value[selectedMetric.value].length == 1) {
    return datapointsForCreateAlert.value[selectedMetric.value][0];
  } else {
    return datapoints.value.find(x => x.id == selectedRange.value);
  }
});
function getMetricWithSymbol(t, type) {
  if (['percent', 'simple_percent'].includes(type)) {
    return `${t}%`;
  }
  if (['dollar', 'live_price'].includes(type)) {
    return `$${t}`;
  }
}
watch(selectedMetricDatapoint, () => {
  if (selectedMetricDatapoint.value) {
    alertData.value.metrics[0].id = selectedMetricDatapoint.value.id;
    alertData.value.metrics[0].type = selectedMetricDatapoint.value.type;
  } else {
    alertData.value.metrics[0].id = null;
    alertData.value.metrics[0].type = null;
  }
});

//COINS
const selectedCoin = ref({});
const coins = computed(() => {
  return store.getters.coins || [];
});
const coinsLoader = computed(() => {
  return store.getters.coinsLoader;
});
const coinsDropdown = computed(() => {
  return coins.value?.map(c => {
    return { id: c.id, text: c.ticker };
  });
});
function selectCoin($event) {
  selectedCoin.value = coins.value.find(c => c.id == $event.id);
  alertData.value.name = selectedCoin.value.ticker;
  alertData.value.coins = selectedCoin.value ? [selectedCoin.value.coin_uid] : [];
}

//COINS METRIC TO DISPLAY
const metricList = ref({
  price_change: {
    primary: 'price',
    relative: 'price_return_24_hours',
    formatter: useRoundedNumber
  },
  relative_tweet_volume: {
    primary: 'tweet_volume',
    relative: 'relative_tweet_volume',
    formatter: useRoundedNumber
  },
  relative_trade_volume: {
    primary: 'trading_volume',
    relative: 'relative_trade_volume',
    formatter: useBigNumberFormatter
  },
  num_active_addrs_24hr_change_pct: {
    primary: 'num_active_addrs_24hr',
    relative: 'num_active_addrs_24hr_change_pct',
    formatter: useRoundedNumber
  }
});
const metricPrimaryValue = computed(() => {
  return selectedCoin.value[metricList.value[selectedMetric.value].primary];
});
const metricPercentChange = computed(() => {
  return selectedCoin.value[metricList.value[selectedMetric.value].relative];
});
const metricValueChange = computed(() => {
  let change = metricList.value[selectedMetric.value].formatter(metricPrimaryValue.value * metricPercentChange.value);
  return metricPercentChange.value >= 0 ? '+' + change : change;
});
</script>
