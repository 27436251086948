import { capitalize } from 'lodash';
import { StreamChat } from 'stream-chat';
export default {
  setApiToken: (state, api_token) => {
    state.session.token = api_token;
    state.session.logged = true;
  },
  setStreamClient: (state, payload) => {
    state.streamUserToken = payload.token;
    state.streamClient = StreamChat.getInstance(payload.key);
  },
  setGiphyKey: (state, payload) => {
    state.giphyApiKey = payload;
  },
  updateStreamUser: (state, payload) => {
    state.streamUser = payload;
  },
  setUnreadChannelsCount: (state, payload) => {
    state.unreadChannelsCount = payload;
  },
  setImageForPreview: (state, payload) => {
    state.previewImage = payload;
  },
  setStreamCompanies: (state, payload) => {
    state.streamCompanies = payload;
  },
  setChannels: (state, payload) => {
    state.channels = payload.channels;
    state.rejectedChannels = payload.rejectedChannels;
    state.mutedChannels = payload.mutedChannels;
  },
  setChannelLoaded: state => {
    state.channelsLoaded = true;
  },
  addMutedChannel: (state, payload) => {
    state.mutedChannels.push(payload);
  },
  removeMutedChannel: (state, payload) => {
    state.mutedChannels = state.mutedChannels.filter(c => c !== payload);
  },
  setFolders: (state, payload) => {
    state.folders = payload;
  },
  updateFolder: (state, payload) => {
    let cIndex = state.folders.findIndex(f => f.folder_id === payload.folder_id);
    state.folders.splice(cIndex, 1);
    state.folders.push(payload);
  },
  deleteFolder: (state, payload) => {
    let cIndex = state.folders.findIndex(f => f.folder_id == payload);
    state.folders.splice(cIndex, 1);
  },
  setStreamUsers: (state, payload) => {
    state.streamUsers = payload;
  },
  removeStreamChannel: (state, payload) => {
    let cIndex = state.channels.findIndex(c => c.cid === payload);
    if (cIndex !== -1) {
      state.channels.splice(cIndex, 1);
    }
  },
  addStreamChannel: (state, payload) => {
    if (payload) {
      let cIndex = state.channels.findIndex(c => c.cid === payload.cid);
      if (cIndex === -1) {
        state.channels.push(payload);
      } else {
        state.channels.splice(cIndex, 1);
        state.channels.push(payload);
      }
    }
  },
  updateStreamChannel: (state, payload) => {
    let cIndex = state.channels.findIndex(c => c.cid === payload.cid);
    if (cIndex != -1) {
      state.channels[cIndex] = payload;
    }
  },
  setRequestChannels: (state, payload) => {
    state.requestChannels = payload;
  },
  updateStreamMessages: (state, payload) => {
    let cIndex = state.channels.findIndex(c => c.cid === payload.channelCid);
    if (cIndex != -1) {
      let formatMessages = payload.messages.map(x => {
        return { ...x, created_at: new Date(x.created_at) };
      });
      state.channels[cIndex].state.messages = formatMessages;
    }
  },
  setMessagingAccess: (state, payload) => {
    state.messagingAccess = payload;
  },
  setUser: (state, user) => {
    state.session.logged = user.user_id ? true : false;
    state.session.user_id = user.user_id;
    state.session.team_id = user.team_id;
    state.session.user_email = user.user_email;
    state.session.roles = user.roles;
    state.session.permissions = user.permissions;
    state.session.status = user.status;
    state.session.team_name = user.team_name;
    state.session.user = user.user;
  },
  setCoins: (state, coins) => {
    state.data.coins = coins.map(x => {
      if (x.tweet_volume < 12) {
        state.sentimentColumns.forEach(i => (x[i] = null));
      }
      return x;
    });
  },
  setVerifiedCoins: (state, verifiedCoins) => {
    state.data.verifiedCoins = verifiedCoins;
  },
  setWatchlists: (state, watchlists) => {
    let data = watchlists.data;
    let remove = watchlists.remove;
    let key = watchlists.key || 'coins';
    if (key == 'screener' || key == 'news' || key == 'coin') {
      key = 'coins';
    } else if (key == 'company') {
      key = 'companies';
    }
    if (remove) {
      state.data.watchlists[key] = state.data.watchlists[key].filter(w => w.name != data);
    } else {
      if (!state.data.watchlists[key].length) {
        state.data.watchlists[key] = data[key] ? data[key] : data;
      } else {
        watchlists.data.forEach(watchlist => {
          let watchlistExist = state.data.watchlists[key].findIndex(x => x.id == watchlist.id);
          if (watchlistExist != -1) {
            state.data.watchlists[key][watchlistExist] = watchlist;
          } else {
            state.data.watchlists[key].push(watchlist);
          }
        });
      }
    }
  },
  updateCoinPrice: (state, payload) => {
    const index = state.data.coins.findIndex(x => x.uid == payload.uid);
    state.data.coins[index].price = payload.latestPrice;

    // WIP
    // const index = state.data.original.findIndex(x => x.ticker == payload.ticker);
    // const originalData = state.data.original[index];
    // const lastHrPrice = originalData.price * (parseFloat(originalData.price_return_1_hour) + 1);
    // const last24HrPrice = originalData.price * (parseFloat(originalData.price_return_24_hours) + 1);
    // const last7DPrice = originalData.price * (parseFloat(originalData.price_return_7_day) + 1);
    // const last30DPrice = originalData.price * (parseFloat(originalData.price_return_30_days) + 1);

    // const btcIndex = state.data.coins.findIndex(x => x.ticker == 'BTC');
    // const btcPrice = coinPrice / state.data.coins[btcIndex].price;
    // const lastHrPriceBTC = btcPrice * (parseFloat(state.data.coins[btcIndex].price_return_btc_1_hour) + 1);
    // const last24HrPriceBTC = btcPrice * (parseFloat(state.data.coins[btcIndex].price_return_btc_24_hours) + 1);
    // const last7DPriceBTC = btcPrice * (parseFloat(state.data.coins[btcIndex].price_return_btc_7_day) + 1);
    // const last30DPriceBTC = btcPrice * (parseFloat(state.data.coins[btcIndex].price_return_btc_30_days) + 1);

    // state.data.coins[index].price = payload.latestPrice;
    // state.data.coins[index].price_return_1_hour = lastHrPrice ? payload.latestPrice / lastHrPrice - 1 : '';
    // if (payload.ticker == 'BTC') {
    // }
    // state.data.coins[index].price_return_24_hours = last24HrPrice ? payload.latestPrice / last24HrPrice - 1 : '';
    // state.data.coins[index].price_return_7_days = last7DPrice ? payload.latestPrice / last7DPrice - 1 : '';
    // state.data.coins[index].price_return_30_days = last30DPrice ? payload.latestPrice / last30DPrice - 1 : '';

    // state.data.coins[index].price_return_btc_1_hour = lastHrPriceBTC ? btcPrice / lastHrPriceBTC - 1 : '';
    // state.data.coins[index].price_return_btc_24_hours = last24HrPriceBTC ? btcPrice / last24HrPriceBTC - 1 : '';
    // state.data.coins[index].price_return_btc_7_days = last7DPriceBTC ? btcPrice / last7DPriceBTC - 1 : '';
    // state.data.coins[index].price_return_btc_30_days = last30DPriceBTC ? btcPrice / last30DPriceBTC - 1 : '';
  },
  updateCoinsWatchlist: (state, payload) => {
    let watchlist = state.data.watchlists.coins.find(x => x.id == payload.id);
    watchlist.entities = payload.entities;
    watchlist.name = payload.name;
  },
  setAllWatchlists: (state, watchlists) => {
    state.data.watchlists = watchlists.data;
  },
  setSearchMode: (state, payload) => {
    state.searchMode = payload;
  },
  setPath: (state, payload) => {
    const parts = payload.split('.');
    state.path = payload;
    state.firstPath = parts[0];
    state.lastPath = parts[parts.length - 1];
  },
  setTags(state, payload) {
    state.tags = payload;
  },
  setUniversal(state, payload) {
    state.data.universal = payload;
  },
  setCategories(state, payload) {
    state.categories = payload;
  },
  setSidebarData(state, payload) {
    state.sidebarData = payload;
  },
  setSidebar(state, payload) {
    state.sidebars[payload.type] = payload.status;
  },
  setCompanySlugs(state, payload) {
    state.companySlugs = payload;
  },
  setSigdevTagSubgroup(state, payload) {
    state.sigdevTagSubgroup = payload;
  },
  setCoinsLoader(state, payload) {
    state.loadingStatus.coins = payload;
  },
  setStaticDataLoader(state, payload) {
    state.loadingStatus.static = payload;
  },
  setCoinDatapoints(state, payload) {
    state.coinDatapointsConfig = payload;
  },
  addCoinToWatchlists(state, payload) {
    if (!['news', 'tradingView'].includes(state.firstPath)) {
      payload.watchlists.forEach(watchlistId => {
        const mainNavItem = state.navItems
          .find(x => x.id.includes(state.firstPath))
          .subpages?.find(x => x.id == 'watchlists');
        if (mainNavItem) {
          mainNavItem.subpages.find(x => x.id == watchlistId).entities.push(payload.entity);
        }
      });
    }
    payload.watchlists.forEach(watchlistId => {
      let watchlist = state.data.watchlists.coins.find(x => x.id == watchlistId);
      let coin = state.data.coins.find(x => x.uid == payload.id);
      watchlist.entities.push(coin);
    });
  },
  removeCoinFromWatchlists(state, payload) {
    if (!['news', 'tradingView'].includes(state.firstPath)) {
      payload.watchlists.forEach(watchlistId => {
        const mainNavItem = state.navItems
          .find(x => x.id.includes(state.firstPath))
          .subpages?.find(x => x.id == 'watchlists');
        if (mainNavItem) {
          const filteredEntities = mainNavItem.subpages
            .find(x => x.id == watchlistId)
            .entities.filter(x => x.uid !== payload.entity.uid);
          mainNavItem.subpages.find(x => x.id == watchlistId).entities = filteredEntities;
        }
      });
    }
    payload.watchlists.forEach(watchlistId => {
      let watchlist = state.data.watchlists.coins.find(x => x.id == watchlistId);
      let eIndex = watchlist.entities.findIndex(x => x.uid == payload.id);
      watchlist.entities.splice(eIndex, 1);
    });
  },
  addCompanyToWatchlists(state, payload) {
    if (state.firstPath !== '') {
      payload.watchlists.forEach(watchlistId => {
        const mainNavItem = state.navItems
          .find(x => x.id.includes(state.firstPath == 'company' ? 'companies' : state.firstPath))
          .subpages.find(x => x.id == 'watchlists');
        if (mainNavItem) {
          mainNavItem.subpages.find(x => x.id == watchlistId).entities.push(payload.entity);
        }
      });
    }
    payload.watchlists.forEach(watchlistId => {
      let watchlist = state.data.watchlists.companies.find(x => x.id == watchlistId);
      payload?.entity?.watchlist_ids?.push(watchlistId);
      watchlist?.entities?.push(payload.entity);
    });
  },
  removeCompanyFromWatchlists(state, payload) {
    if (state.firstPath !== '') {
      payload.watchlists.forEach(watchlistId => {
        const mainNavItem = state.navItems
          .find(x => x.id.includes(state.firstPath))
          .subpages.find(x => x.id == 'watchlists');
        if (mainNavItem) {
          const filteredEntities = mainNavItem.subpages
            .find(x => x.id == watchlistId)
            .entities.filter(x => x.id !== payload.entity.id);
          mainNavItem.subpages.find(x => x.id == watchlistId).entities = filteredEntities;
        }
      });
    }
    payload?.watchlists?.forEach(watchlistId => {
      let watchlist = state.data.watchlists.companies.find(x => x.id == watchlistId);
      let eIndex = watchlist?.entities?.findIndex(x => x.id == payload.id);
      watchlist?.entities?.splice(eIndex, 1);
    });
  },
  setSidebarCollapsed(state, collapsedState) {
    state.sidebarCollapsed = collapsedState;
    localStorage.setItem('sidebarCollapsed', collapsedState);
  },
  toggleSidebar(state) {
    state.sidebarCollapsedInt = !state.sidebarCollapsedInt;
  },
  setSidebarState(state, value) {
    state.sidebarCollapsedInt = value;
  },
  setHasSidebar(state, val) {
    state.hasSidebar = val;
  },
  openModal(state, payload) {
    state.modal = payload;
  },
  closeModal(state) {
    state.modal = {
      name: '',
      status: false,
      payload: {}
    };
  },
  setMetricTooltips(state, payload) {
    state.metricTooltips = payload;
  },
  setRoles(state, payload) {
    state.team_roles = payload.team_roles;
    state.user_roles = payload.user_roles;
  },
  setUserNotifications(state, payload) {
    state.userNotifications = payload;
  },
  changeUserNotificationStatus(state, payload) {
    if (payload.id == 0) {
      state.userNotifications = state.userNotifications.map(x => {
        let a = x;
        a.read = true;
        return a;
      });
    } else {
      let index = state.userNotifications.findIndex(x => x.id == payload.id);
      state.userNotifications[index].read = payload.status;
    }
  },
  addNewUserNotification(state, payload) {
    state.userNotifications.unshift(payload);
  },
  removeUserNotification(state, id) {
    state.userNotifications = state.userNotifications.filter(x => x.id != id);
  },
  setWidgetOutputChannel(state, payload) {
    state.widgetOutputChannels[payload.channel] = payload.value;
  },
  clearWidgetOutputChannel(state, channel) {
    state.widgetOutputChannels[channel] = null;
  },
  setConfirmationDialogOptions(state, payload) {
    let defaultOptions = state.confirmationDialogOptions;
    let modifiedOptions = {};
    if (typeof payload === 'string') {
      modifiedOptions = { ...defaultOptions, message: payload, show: true };
    } else {
      modifiedOptions = { ...defaultOptions, ...payload, show: true };
    }
    state.confirmationDialogOptions = modifiedOptions;
  },
  resetConfirmationDialogOptions(state) {
    state.confirmationDialogOptions = {
      show: false,
      resolve: null,
      title: 'Action Required',
      message: 'Are you sure?',
      confirmText: 'Proceed',
      rejectText: 'Cancel'
    };
  },
  resolveConfirmation(state, payload) {
    state.confirmationDialogOptions.resolve = payload;
  },
  setNftChain(state, payload) {
    state.nftChain = payload;
  },
  setCoinChain(state, payload) {
    state.coinChain = payload;
  }
};
