<template>
  <component
    :is="widget.widget_template.template"
    :widget="widget"
    :base-theme="baseTheme"
    :override="override"
    :template-id="templateId"
    :tvl-coins="tvlCoins"
    :widget-access-level="widgetAccessLevel"
  />
</template>
<script setup>
import axios from 'axios';
import { useStore } from 'vuex';
import { onBeforeMount, onMounted } from 'vue';

const $store = useStore();
const props = defineProps({
  coinsData: Array,
  coinCategories: Object,
  tvlCoins: Array,
  widget: Object,
  baseTheme: Object,
  override: Object,
  templateId: String,
  widgetAccessLevel: { type: String, default: 'full' }
});

onBeforeMount(() => {
  if (props.coinsData) {
    $store.dispatch('setCoinsAndCategories', {
      coins: props.coinsData,
      categories: props.coinCategories
    });
  }
});

onMounted(() => {
  if (props.coinsData) {
    subscribeToUpdates();
    setInterval(() => {
      $store.dispatch('setIntegrationTeamCoins');
    }, 60000);
  }
});
function subscribeToUpdates() {
  setInterval(async () => {
    const response = await axios.get('/integrations/latest_updates');
    if (response?.data) {
      $store.dispatch('updateLivePrices', response.data);
    }
  }, 40000);
}
</script>
