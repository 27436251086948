<template>
  <div class="h-full">
    <NexoWidgetsBase
      :widget="widget"
      :base-theme="baseTheme"
      :loading="loading"
      :widget-access-level="widgetAccessLevel"
    >
      <template #body>
        <template v-if="!coinTicker || chartError">
          <NexoNoDataError />
        </template>
        <div v-else class="h-full w-full py-4 px-6">
          <div class="flex w-full flex-row items-center gap-2 border-b border-gray-800 px-2 pb-2">
            <NexoDropdownMultiSelect
              :input-class="'flex items-center justify-between'"
              :hide-clear="true"
              :items="metrics"
              :dropdown-height="'300px'"
              :dropdown-width="'250px'"
              :selected-items="selectedMetrics"
              @selected="selectMetric"
            />
            <NexoDropdownSearch
              custom-classes="mt-2"
              :truncate-value="20"
              @selected="compareCoin = $event.id"
              :items="coinOptions"
              :active-item="compareCoin"
              placeholder="Select Coin"
              dropdown-width="250px"
            />
            <NexoTags
              v-if="series && series.length <= 4"
              :tags="[{ id: 'relative', label: 'Set Relative' }]"
              tag-class="mr-0 py-[5px]"
              v-model="selectedTag"
              @update:model-value="toggleRelative"
            />
            <NexoButton
              @press="resetConfiguration"
              style="min-height: 22px"
              size="sm"
              type="secondary"
              class="bg-skin-btn-secondary-accent rounded !py-0"
            >
              Reset
            </NexoButton>
          </div>
          <Chart class="overflow-hidden p-2" style="height: calc(100% - 30px)" :options="chartOptions" ref="chart" />
        </div>
      </template>
    </NexoWidgetsBase>
  </div>
</template>

<script setup>
import moment from 'moment';
import { useStore } from 'vuex';
import { orderBy } from 'lodash';
import { DateTime } from 'luxon';
import { Chart } from 'highcharts-vue';
import { formatNumber } from '@/composeables/filters';
import colors from 'tailwindcss/colors';
import { onBeforeMount, ref, computed, watch, nextTick, inject } from 'vue';

const store = useStore();
const $http = inject('http');

const props = defineProps({
  widget: { type: Object, required: true },
  baseTheme: { type: Object },
  overrides: { type: Object },
  templateId: { type: String },
  widgetAccessLevel: { type: String }
});

const coinTicker = ref();
const chain = ref('ethereum');
const loading = ref(true);
onBeforeMount(async () => {
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has('coin')) {
    coinTicker.value = urlParams.get('coin').toLowerCase();
  } else {
    loading.value = false;
    chartError.value = true;
  }
  if (urlParams.has('chain')) {
    chain.value = urlParams.get('chain');
  }
  await getOnchainCoins();
});

function resetConfiguration() {
  selectedMetrics.value = [{ id: 'num_active_addrs_24h', name: 'Active Addresses' }];
  relative.value = false;
  compareCoin.value = null;
}

//METRICS
const selectedMetrics = ref([{ id: 'num_active_addrs_24h', name: 'Active Addresses' }]);
const metrics = ref([
  { id: 'price', name: 'Price' },
  { id: 'num_active_addrs_24h', name: 'Active Addresses' },
  { id: 'num_active_addrs_30d_avg', name: 'Active Addresses (30d Avg)' },
  { id: 'num_inc_24', name: 'Addresses with Increased Balance' },
  { id: 'num_inc_30d_avg', name: 'Addresses with Increased Balance (30d Avg)' },
  { id: 'num_dec_24', name: 'Addresses with Decreased Balance' },
  { id: 'num_dec_30d_avg', name: 'Addresses with Decreased Balance (30d Avg)' },
  { id: 'num_txs_24h', name: 'Transactions' },
  { id: 'num_tx_30d_avg', name: 'Transactions (30d Avg)' }
]);
function selectMetric(payload) {
  let index = selectedMetrics.value.findIndex(x => x.id == payload.id);
  if (index >= 0) {
    selectedMetrics.value.splice(index, 1);
  } else {
    selectedMetrics.value.push(payload);
  }
}
watch(
  selectedMetrics,
  () => {
    if (series.value && series.value.length > 4) {
      relative.value = true;
      selectedTag.value = 'relative';
    }
    plotChart();
  },
  { deep: true }
);

// Comparison Coin
const compareCoin = ref(null);
const compareCoinHistorySeries = ref(new Map());

watch(compareCoin, async () => {
  chart.value?.chart.showLoading();
  compareCoinHistorySeries.value.clear();

  if (compareCoin.value) {
    const compareCoinTicker = coins.value.find(x => x.uid === compareCoin.value)?.ticker.toLowerCase();
    compareCoinHistorySeries.value = await getOnchainHistorySeries(compareCoinTicker);
  }

  if (series.value && series.value.length > 4) relative.value = true;
  selectedTag.value = relative.value ? 'relative' : '';
  nextTick(() => {
    chart.value?.chart.hideLoading();
    plotChart();
  });
});

// Series Calculations
const coinHistorySeries = ref(new Map());
const series = computed(() => {
  if (coinHistorySeries.value.size == 0 || !currentCoin.value) return [];

  const coinName = coins.value.find(coin => coin.ticker.toLowerCase() == coinTicker.value)?.name;
  const coinSeries = selectedMetrics.value
    .map(metric => nameSeries(coinHistorySeries.value.get(metric.id), coinName))
    .map(rec => {
      rec.data = orderBy(rec.data, [
        item => {
          return item[0];
        }
      ]);
      return rec;
    });

  let compareCoinSeries = [];
  if (compareCoin.value && compareCoinHistorySeries.value.size) {
    const compareCoinName = coins.value.find(coin => coin.coin_uid == compareCoin.value).name;
    compareCoinSeries = selectedMetrics.value
      .map(metric => nameSeries(compareCoinHistorySeries.value.get(metric.id), compareCoinName))
      .map(rec => {
        rec.data = orderBy(rec.data, [
          item => {
            return item[0];
          }
        ]);
        return rec;
      });
  }

  let chartSeries = [...coinSeries, ...compareCoinSeries];
  return chartSeries.map((s, ind) => {
    return {
      ...s,
      yAxis: ind
    };
  });
});

const nameSeries = (series, name) => {
  return {
    name: `${series?.name} - ${name}`,
    data: series?.data?.filter(rec => rec[0] && rec[1]) || []
  };
};

const relativeSeries = computed(() => {
  return series.value.map(s => {
    const firstValue = s.data[0] ? s.data[0][1] : null;
    return {
      name: s?.name,
      data: s.data.map((d, index) => {
        return [d[0], index == 0 ? 0 : firstValue ? (d[1] / firstValue - 1) * 100 : ''];
      })
    };
  });
});

//GET COIN HISTORY SERIES
async function getOnchainHistorySeries(coin) {
  const seriesMap = new Map();

  const response = await $http.get('/integrations/onchain_historical', { params: { coin: coin } });
  const priceHistory = response.data?.price_history || [];
  const onchainHistory = response.data?.onchain_history || [];

  setCoinHistorySeries(['price'], priceHistory, seriesMap, 'datetime');
  setCoinHistorySeries(
    metrics.value.slice(1).map(m => m.id),
    onchainHistory,
    seriesMap,
    'datetime'
  );

  return seriesMap;
}
function setCoinHistorySeries(tempMetrics, coinHistoryData, seriesMap, datetimeAttr) {
  tempMetrics.forEach(metric => {
    seriesMap.set(metric, {
      name: metrics.value.find(m => m.id == metric)?.name,
      data: coinHistoryData.map(c => {
        return [new Date(c[datetimeAttr]).getTime(), parseInt(c[metric])];
      })
    });
  });
}

// Chart Settings
const relative = ref(false);
const selectedTag = ref('');
const chart = ref(null);
const chartError = ref(false);
function plotChart() {
  if (currentCoin.value) {
    try {
      const yaxis = relative.value
        ? {
            title: {
              enabled: true,
              text: 'Percentage Change'
            },
            labels: {
              formatter: point => `${formatNumber(point.value)}%`
            }
          }
        : series.value.map((s, index) => {
            return {
              opposite: compareCoin.value ? index > (series.value.length - 1) / 2 : index % 2 == 1,
              title: {
                enabled: true,
                text: s?.name
              },
              labels: {
                offsetX: 2,
                formatter: function (point) {
                  return formatNumber(point.value);
                }
              }
            };
          });
      const tooltip = {
        formatter: function () {
          const date = moment(this.x).format('DD MMM YYYY');
          let tipHtml = `<div class='max-w-md break-words rounded py-1 px-2 text-left space-y-1 font-medium'><b>${date}</b></br>`;
          const fromattedPnts = this.points.map(point => {
            var value = '';
            value = relative.value ? `${formatNumber(point.y, 2)}%` : formatNumber(point.y, 2);
            const pointSymb = this.points.length === 1 ? '' : `<span style="color:${point.color}">●</span>`;
            return pointSymb + ` ${point.series?.name}: <span style="margin-left: 0.5rem;">${value}</span>`;
          });

          return tipHtml + fromattedPnts.join('<br/>') + '</div>';
        }
      };
      nextTick(() => {
        chartOptions.value.yAxis = yaxis;
        nextTick(() => {
          chartOptions.value.tooltip = tooltip;
          chartOptions.value.series = relative.value ? relativeSeries.value : series.value;
        });
      });
    } catch (e) {
      chartError.value = true;
    } finally {
      // chartError.value = false;
      loading.value = false;
    }
  }
}
const chartOptions = ref({
  chart: {
    type: 'line'
  },
  plotOptions: {
    series: {
      animation: false,
      marker: {
        enabled: false,
        states: {
          hover: { enabled: false }
        }
      }
    }
  },
  colors: [
    colors.blue[700],
    colors.amber[700],
    colors.green[700],
    colors.indigo[700],
    colors.purple[700],
    colors.pink[700],
    colors.rose[700],
    colors.cyan[700],
    colors.blue[600],
    colors.amber[600],
    colors.green[600],
    colors.indigo[600],
    colors.purple[600],
    colors.pink[600],
    colors.rose[600],
    colors.blue[500],
    colors.amber[500],
    colors.green[500],
    colors.indigo[500],
    colors.purple[500],
    colors.pink[500],
    colors.rose[500],
    colors.blue[400],
    colors.amber[400],
    colors.green[400],
    colors.indigo[400],
    colors.purple[400],
    colors.pink[400],
    colors.rose[400],
    colors.blue[300],
    colors.amber[300],
    colors.green[300],
    colors.indigo[300],
    colors.purple[300],
    colors.pink[300],
    colors.rose[300]
  ],
  legend: {
    enabled: true,
    itemStyle: {
      color: colors.gray[400],
      fontSize: '11px',
      fontWeight: 'medium'
    },
    itemHoverStyle: {
      color: colors.gray[200],
      fontSize: '11px',
      fontWeight: 'medium'
    }
  }
});
function toggleRelative() {
  relative.value = !relative.value;
  selectedTag.value = relative.value ? 'relative' : '';
  plotChart();
}

//CURRENT COIN
const supportedCoins = ref(null);
const coins = computed(() => store.getters.coins);
async function getOnchainCoins() {
  let response = await $http.get('/integrations/coins/onchain_coins');
  supportedCoins.value = response.data.coins;
  checkOnchainCoin();
}
const currentCoin = computed(() => {
  return coins.value?.find(x => x.ticker.toLowerCase() == coinTicker.value) || null;
});
watch(
  () => currentCoin.value?.ticker,
  () => {
    checkOnchainCoin();
  }
);

async function checkOnchainCoin() {
  if (supportedCoins.value?.map(c => c.ticker.toLowerCase()).includes(coinTicker.value)) {
    coinHistorySeries.value = await getOnchainHistorySeries(coinTicker.value);

    nextTick(() => {
      if (series.value && series.value.every(x => x.data.length === 0)) {
        chartError.value = true;
      }
    });

    plotChart();
  } else if (coinTicker.value && supportedCoins.value) {
    loading.value = false;
    chartError.value = true;
  }
}
// COINS FOR DROPDOWN
const coinOptions = computed(() => {
  if (coins.value.length && supportedCoins.value?.length) {
    const supportedCoinUids = supportedCoins.value.map(c => c.uid);
    return coins.value
      .filter(c => supportedCoinUids.includes(c.uid))
      .map(c => {
        return {
          id: c.uid,
          text: c.name,
          ticker: c.ticker
        };
      });
  } else {
    return [];
  }
});
</script>
