import axios from 'axios';
import qs from 'qs';

export async function fetchCoins(items, filters) {
  const response = await axios.get('/coins/coin-data', {
    params: {
      items: items,
      filters: filters
    },
    paramsSerializer: params => {
      return qs.stringify(params, { arrayFormat: 'brackets' });
    }
  });
  return response.data.data.map(coin => {
    return { ...coin, coin: { name: coin.name, ticker: coin.ticker } };
  });
}

export async function fetchSentiment(categories) {
  return axios.post('/coins/sentiment-data', {
    categories: categories
  });
}
export async function savePageVisits(route) {
  return axios.post('/add_page_visits', { path: route.path, name: route.name });
}

export async function fetchMarketData(categories) {
  return axios.post('/coins/market-data', {
    categories: categories
  });
}

export async function fetchTwitterData(categories) {
  return axios.post('/coins/tweets-data', {
    categories: categories
  });
}

export async function fetchUniversalData() {
  return (await axios.get('/coins/universal-data')).data;
}

export async function fetchCategories() {
  return (await axios.get('/categories')).data;
}

export async function fetchGasFees() {
  return (await axios.get('/gas_fees')).data;
}

export async function GasFeeGraphData() {
  return (await axios.get('/gas_fees')).data;
}

export async function loadStaticData() {
  return (await axios.get('/static-data')).data;
}
export async function loadUserNotifications() {
  return (await axios.get('/notifications', { params: { paginate: false } })).data;
}

export async function fetchWatchlists() {
  return (await axios.get('/watchlists')).data;
}

export async function fetchIntegrationTeamCoins() {
  const response = await axios.get('/integrations/coins');
  return response.data.data.map(coin => {
    return { ...coin, coin: { name: coin.name, ticker: coin.ticker } };
  });
}

export async function fetchIntegrationCategories() {
  return (await axios.get('/integrations/categories')).data;
}
