<template>
  <div
    class="z-50 select-none overflow-hidden overflow-y-auto rounded-md border border-gray-700 bg-gray-800 text-xs text-white shadow"
    :class="[customClasses, maxHeight, width, `${xAnchor}-0`, useParentContainer ? '' : 'absolute']"
    :style="customStyles ? customStyles : dropdownWidth ? `min-width:${dropdownWidth} !important` : ''"
  >
    <slot />
    <template v-if="linkItems">
      <span v-for="(row, idx) in orderedItems" :key="idx">
        <a
          v-if="row.openModal || (row.id && row.id === 'sign_out')"
          @click="clicked(row.id)"
          :class="[dropDownItemClass, 'cursor-pointer']"
        >
          {{ row.text }}
        </a>
        <InertiaLink
          v-else
          :data-savvycal-embed="row.attribute ? row.attribute : null"
          :href="row.link"
          :target="row.newTab ? '_blank' : ''"
          :data-email="row.email ? row.email : null"
          :data-display-name="row.username ? row.username : null"
          :class="dropDownItemClass"
        >
          {{ row.text }}
        </InertiaLink>
      </span>
    </template>
    <template v-else>
      <div
        v-for="row in orderedItems"
        :key="row.id"
        @click="clicked(row.id)"
        :style="row.style"
        :class="`
          ${row.class}
          ${backgroundColor}
          text-left
          ${row.selected ? selectedDropDownItemClass : dropDownItemClass}
          ${capitalize ? 'capitalize' : ''}
          ${row.checked !== undefined && 'flex items-center space-x-2'} ${customDropdownItemClass}

        `"
      >
        <template v-if="row.html">
          <BaseTooltip>
            <template #target><div v-html="row.html"></div></template>
            <template #default v-if="tooltip && row.text && row.text.length > truncateLimit">{{ row.text }}</template>
          </BaseTooltip>
        </template>
        <template v-else>
          <input type="checkbox" v-if="row.checked !== undefined" :checked="row.checked" />
          <div class="break-words" v-if="truncateText">{{ truncate(row.text, 10) }}</div>
          <div class="whitespace-normal break-words" v-else>{{ row.text }}</div>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import { orderBy } from 'lodash';
import { Link as InertiaLink } from '@inertiajs/inertia-vue3';
import FilterMixin from '@/mixins/filters';

export default {
  mixins: [FilterMixin],
  components: { InertiaLink },
  data() {
    return {
      selectedDropDownItemClass:
        'block py-2 text-md px-3 select-none text-gray-100 hover:text-white bg-gray-700 duration-100',
      dropDownItemClass:
        'block py-2 text-md px-3 select-none text-gray-300 hover:text-white bg-gray-800 hover:bg-blue-900/50 duration-100'
    };
  },
  props: {
    items: { type: Array, default: () => [] },
    capitalize: { type: Boolean, default: true },
    dropdownWidth: { type: String },
    xAnchor: { type: String, default: 'right' },
    linkItems: Boolean,
    width: { type: String, default: 'w-full' },
    useParentContainer: Boolean,
    backgroundColor: { type: String, default: 'bg-gray-900' },
    customClasses: { type: String, default: 'mt-2' },
    customStyles: { type: String },
    maxHeight: { type: String, default: 'max-h-64' },
    customDropdownItemClass: { type: String, default: '' },
    truncateText: { type: Boolean, default: false },
    tooltip: { type: Boolean, default: false },
    truncateLimit: { type: Number, default: 200 },
    sortOptions: { type: Boolean, default: false },
    sortKey: { type: String, default: 'text' },
    sortOrder: { type: String, default: 'asc' }
  },
  methods: {
    clicked: function (item) {
      this.$emit('clicked', item);
    }
  },
  computed: {
    orderedItems() {
      if (this.sortOptions) {
        return orderBy(this.items, [item => item[this.sortKey]?.toLowerCase()], this.sortOrder);
      } else {
        return this.items;
      }
    }
  }
};
</script>
