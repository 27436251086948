import { ref } from 'vue';
import { DateTime } from 'luxon';
import useHttp from '@/composeables//http';
import useEmitter from '@/composeables/emitter';
import SimpleNewsFeedItem from '@/components/integrations/nexo/NexoSimpleNewsFeedItem.vue';
export default function useNewsMixin() {
  const $http = useHttp();
  const $eventHub = useEmitter();
  const showScrollToTop = ref(false);
  const latestTimestamp = ref(null);
  const news = ref([]);
  const itemComponent = ref(SimpleNewsFeedItem);
  const pollingInterval = ref(null);
  const endTimestamp = ref(null);
  const loading = ref(true);
  const scrollToTop = scroller => {
    showScrollToTop.value = false;
    scroller.scrollToOffset(0);
  };
  const fetch = payload => {
    return $http.get('/integrations/news.json', {
      params: payload
    });
  };
  const getNewsFromCache = newsParams => {
    let payload = newsParams;
    fetch(payload)
      .then(response => {
        news.value = response.data.data;
      })
      .finally(() => {
        loading.value = false;
      });
  };
  const getNews = newsParams => {
    let payload = newsParams;
    if (payload) {
      if (latestTimestamp.value) {
        payload.start_datetime = DateTime.fromISO(latestTimestamp.value).plus({ seconds: 1 }).toISO();
      }
      payload.limit = 25;
      fetch(payload)
        .then(response => {
          let newNews = response.data.data;
          if (newNews.length > 0) {
            newNews.reverse().forEach(item => {
              news.value.unshift(item);
            });
          }
          if (news.value && news.value[0]) {
            latestTimestamp.value = news.value[0].timestamp;
          }
        })
        .finally(() => {
          $eventHub.$emit('apply-widget-theme');
          loading.value = false;
        });
    } else {
      loading.value = false;
    }
  };
  const getOlderNews = newsParams => {
    showScrollToTop.value = true;
    let payload = newsParams;
    if (payload) {
      payload.start_datetime = null;
      payload.end_datetime = news.value[news.value.length - 1].timestamp;
      payload.limit = 50;
      fetch(payload).then(response => {
        let newNews = response.data.data;
        if (newNews.length > 0) {
          newNews.forEach(item => {
            news.value.push(item);
          });
        }
      });
    }
  };
  return {
    showScrollToTop,
    latestTimestamp,
    news,
    itemComponent,
    pollingInterval,
    endTimestamp,
    loading,
    scrollToTop,
    fetch,
    getNews,
    getOlderNews,
    getNewsFromCache
  };
}
