<template>
  <div :class="widthClass">
    <div :id="popperId">
      <slot name="trigger" />
    </div>
    <div :id="dropdownId" class="z-20">
      <slot name="dropdown" />
    </div>
  </div>
</template>
<script setup>
import { createPopper } from '@popperjs/core';
import { onMounted, watch, ref } from 'vue';

const props = defineProps({
  on: Boolean,
  placement: String,
  widthClass: String
});

const popperInstance = ref(null);
const popperId = `popper_${Math.random()}`;
const dropdownId = `dropdown_${Math.random()}`;
const popper = ref(null);
const dropdown = ref(null);

onMounted(() => {
  popper.value = document.getElementById(popperId);
  dropdown.value = document.getElementById(dropdownId);
});

watch(
  () => props.on,
  newVal => {
    if (newVal == true) {
      popperInstance.value = createPopper(popper.value, dropdown.value, {
        placement: props.placement || 'bottom'
      });
    } else if (popperInstance.value) {
      popperInstance.value.destroy();
    }
  }
);
</script>
