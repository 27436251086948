import axios from 'axios';
import notify from '@/composeables/notify.js';
import store from '@/store/index';
import { Inertia } from '@inertiajs/inertia';

export async function createEvent(params) {
  axios.post('/mixpanel_events', params);
}

export function getColor() {
  let color = '#';
  for (let i = 0; i < 3; i++)
    color += ('0' + Math.floor(((1 + Math.random()) * Math.pow(16, 2)) / 2).toString(16)).slice(-2);
  return color;
}

export function fixScrolling(action) {
  let hoverElem = document.querySelector('.element-hover');
  if (hoverElem !== null) {
    if (action === 'down') {
      hoverElem.scrollIntoView({ block: 'nearest' });
    } else if (action === 'up' && hoverElem.previousElementSibling) {
      hoverElem.previousElementSibling.scrollIntoView({ block: 'nearest' });
    }
  }
}

export function copyToClipboard(textToCopy, message = 'Copied to clipboard!') {
  window.navigator.clipboard.writeText(textToCopy).then(() => {
    //debug why flash doesn't work here for some reason
    flashMessage({
      type: 'success',
      message: message
    });
  });
}

export function goToPreviousPage() {
  //2 just to make sure we are not landing directly on the page
  if (window.history.length && window.history.length > 2) {
    window.history.back();
  } else {
    Inertia.visit('/dashboard');
  }
}

export function flashMessage(content, clickEvent = null) {
  notify(
    {
      group: 'app',
      type: 'flash',
      text: content,
      onClick: clickEvent
    },
    5000
  );
}

export function groupBy(list, keyGetter) {
  const map = new Map();
  list.forEach(item => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

export function tooltipDefinitionExists(id) {
  let metricTooltips = store.getters.metricTooltips;
  if (metricTooltips) {
    return metricTooltips.findIndex(x => x.metric_key == id) >= 0;
  }
  return false;
}

export function isCoinVerified(uid) {
  return store.getters.verifiedCoins.includes(uid);
}

export function applyRawCSS(cssString) {
  if (cssString && cssString.trim() != '') {
    const matches = cssString.matchAll(/\.(\w.+){\n?([^}]*)\n?}/g);
    for (const match of matches) {
      document.querySelectorAll('.' + match[1]).forEach(el => {
        el.style = match[2].replace(/[\r\n\t]/g, '').replaceAll(';', ' !important;');
      });
    }
  }
}

export function applyFontFamily(fontString, fontFace) {
  try {
    document.querySelectorAll(`[id='custom-font-style']`).forEach(element => {
      document.head.removeChild(element);
    });
    if (fontFace) {
      let fonts = fontString
        .split('@font-face')
        .filter(x => x != '')
        .map(x => '@font-face' + x);
      const styleEl = document.createElement('style');
      styleEl.id = 'custom-font-style';
      document.head.appendChild(styleEl);
      var sheet = styleEl.sheet;
      fonts.forEach(font => {
        sheet.insertRule(font, sheet.cssRules.length);
      });
    } else {
      let links = fontString.split(',').map(el => el.trim());
      links.forEach(str => {
        if (str.trim().length != 0) {
          var link = document.createElement('link');
          link.rel = 'stylesheet';
          link.href = str;
          link.id = 'custom-font-style';
          document.getElementsByTagName('head')[0].appendChild(link);
        }
      });
    }
  } catch (error) {
    // do nothing
  }
}

export function sortByMarketCapAllCategories(column) {
  // arrange categories and subcategories on MCap base value
  let categoriesList = [];
  let subCategoriesList = [];
  let allCategories = store.state.categories.coin || [];
  let allSubCategories = store.state.categories.coin_sub_categories;
  let coins = store.state.data.coins;
  if (column.includeCategories) {
    let marketCapList = [];
    categoriesList = allCategories.map(x => {
      if (x.flat_coin_uids) {
        marketCapList = coins.map(c => {
          if (x.flat_coin_uids.includes(c.coin_uid)) {
            return parseFloat(c.market_cap);
          }
          return 0;
        });
      }
      return {
        id: 'cat_' + x.id,
        text: x.name,
        label: x.name,
        name: x.name,
        flat_coin_uids: x.flat_coin_uids,
        mCap: marketCapList.filter(Boolean).reduce((partialSum, a) => parseFloat(partialSum) + parseFloat(a), 0)
      };
    });
  }

  if (column.includeSubcategories) {
    let marketCapList = [];
    subCategoriesList = allSubCategories?.map(x => {
      if (x.flat_coin_uids) {
        marketCapList = coins.map(c => {
          if (x.flat_coin_uids.includes(c.coin_uid)) {
            return parseFloat(c.market_cap);
          }
          return 0;
        });
      }
      return {
        id: 'subCat_' + x.id,
        text: x.name,
        label: x.name,
        name: x.name,
        cat_id: x.category_id,
        flat_coin_uids: x.flat_coin_uids,
        mCap: marketCapList.filter(Boolean).reduce((partialSum, a) => parseFloat(partialSum) + parseFloat(a), 0)
      };
    });
  }

  return [...categoriesList, ...subCategoriesList].sort((a, b) => (a.mCap > b.mCap ? -1 : 1));
}

export default {
  createEvent,
  getColor,
  fixScrolling,
  groupBy,
  isCoinVerified,
  applyRawCSS,
  applyFontFamily,
  sortByMarketCapAllCategories
};
