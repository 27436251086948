<template>
  <div class="h-full">
    <NexoWidgetsBase :widget="widget" :base-theme="baseTheme" :loading="coinLoading">
      <template #body>
        <template v-if="!currentCoin">
          <NexoNoDataError />
        </template>
        <template v-else>
          <div class="space-y-2 p-8">
            <div class="space-y-8 md:flex md:space-y-0">
              <div class="flex w-full flex-col justify-between space-y-2 md:w-4/5">
                <div class="flex-1">
                  <div class="flex items-center justify-between">
                    <div class="flex items-center space-x-8">
                      <div>
                        <NexoLabel> Price </NexoLabel>
                        <span class="block text-xl font-medium text-gray-100">
                          {{ usePriceFormatter(currentCoin.price) }}
                        </span>
                      </div>
                      <div>
                        <NexoLabel> Change </NexoLabel>
                        <NexoPercentage :percentage="currentCoin.price_return_24_hours" class="text-xl font-medium" />
                      </div>
                    </div>
                    <NexoTags :tags="timeIntervals" v-model="selectedInterval" @update:model-value="loadCoinHistory" />
                  </div>
                  <div class="mt-2 h-60">
                    <div id="plot" class="h-full"></div>
                  </div>
                </div>
              </div>
              <div class="relative h-[17rem] w-full md:w-1/5">
                <div
                  v-if="widgetAccessLevel == 'restricted'"
                  class="absolute inset-0 z-50 w-full backdrop-blur-md"
                ></div>
                <div class="flex h-full justify-center space-x-8">
                  <div v-for="dp in twitterDatapoints" :key="dp.primary" class="flex flex-col justify-between">
                    <div class="flex flex-col items-center">
                      <div class="flex items-center space-x-1">
                        <NexoLabel> {{ dp.primary_label }} </NexoLabel>
                        <SharedMetricTooltip :datapoint="dp.primary" />
                      </div>
                      <template v-if="coinTwitterData[dp.primary]">
                        <span v-if="dp.formatter != 'percent'" class="block text-xl font-medium text-gray-100">
                          {{ dp.formatter(coinTwitterData[dp.primary]) }}
                        </span>
                        <NexoPercentage
                          v-if="dp.formatter == 'percent'"
                          :percentage="coinTwitterData[dp.primary]"
                          class="text-xl font-medium"
                        />
                      </template>
                    </div>
                    <div class="group flex flex-col items-center">
                      <div :class="dp.colors.text" class="mb-2 text-xs group-hover:font-bold">
                        {{ useNumberFormatter(coinTwitterData[dp.secondary]) }}
                      </div>
                      <div class="flex h-32 flex-1">
                        <div
                          :style="`height: ${playAnimation ? getBarHeight(coinTwitterData[dp.secondary]) : 0}px`"
                          :class="[
                            dp.colors.outline,
                            'w-16 transform rounded-tl rounded-tr bg-gradient-to-b p-px transition-[height] duration-1000 ease-in-out'
                          ]"
                        >
                          <div class="bg-skin-fill h-full w-full rounded-tr rounded-tl">
                            <div
                              :class="[
                                dp.colors.fill,
                                dp.colors.hover,
                                'h-full w-full rounded-tl rounded-tr bg-gradient-to-b'
                              ]"
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div class="flex flex-col text-center">
                        <NexoLabel>{{ dp.secondary_label }} </NexoLabel>
                        <span :class="[dp.colors.text, 'block, text-xs font-medium']">
                          {{ dp.colors.label }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex justify-between border-t border-gray-800 py-8">
              <div v-for="dp in datapoints" :key="dp.primary" class="flex flex-col space-y-1">
                <NexoLabel> {{ dp.label }} </NexoLabel>
                <template
                  v-if="currentCoin[dp.id] && (tvlCoins.includes(currentCoin.uid) || !dp.label.includes('TVL'))"
                >
                  <span v-if="dp.formatter != 'percent'" class="block text-xs font-medium text-gray-100">
                    {{ dp.formatter(currentCoin[dp.id]) }}
                  </span>
                  <NexoPercentage
                    v-if="dp.formatter == 'percent'"
                    class="text-xs font-medium"
                    :percentage="currentCoin[dp.id]"
                    :show-arrow="false"
                  />
                </template>
                <template v-else>
                  <span
                    :class="dp.formatter == 'percent' ? 'text-right' : ''"
                    class="block text-xs font-medium text-gray-100"
                  >
                    -
                  </span>
                </template>
              </div>
            </div>
          </div>
        </template>
      </template>
    </NexoWidgetsBase>
  </div>
</template>
<script setup>
import Highcharts from 'highcharts/highcharts';
import colors from 'tailwindcss/colors';
import PlaceholderImage from '@/images/nexo/coinprofile.png';
import { useStore } from 'vuex';
import { min, max } from 'lodash';
import moment from 'moment';
import { ref, inject, computed, watch, onMounted, onBeforeMount, nextTick } from 'vue';
import { usePriceFormatter, useBigNumberFormatter, useNumberFormatter } from '@/composeables/integrations/nexo/helpers';

const $store = useStore();
const $http = inject('http');
const props = defineProps({
  widget: { type: Object, required: true },
  baseTheme: { type: Object },
  overrides: { type: Object },
  templateId: { type: String },
  tvlCoins: { type: Array },
  widgetAccessLevel: { type: String }
});

//ANIMATION
const playAnimation = ref(false);
onMounted(() => {
  setTimeout(() => {
    playAnimation.value = true;
  }, 1000);
});

//INTERVALS
const selectedInterval = ref(0);
const timeIntervals = [
  { id: 0, interval: '5min', label: '1D' },
  { id: 1, interval: '1hour', label: '1M' },
  { id: 2, interval: '1day', label: '1Y' },
  { id: 3, interval: '1day', label: '5Y' },
  { id: 4, interval: '1day', label: 'YTD' }
];

//COIN
const coinLoading = ref(true);
const coinTicker = ref(null);
onBeforeMount(() => {
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has('coin')) {
    coinTicker.value = urlParams.get('coin').toLowerCase();
    loadCoinHistory();
  } else {
    coinLoading.value = false;
  }
});
const coins = computed(() => {
  return $store.getters.coins;
});
const currentCoin = computed(() => {
  return coins.value.find(x => x.ticker.toLowerCase() == coinTicker.value) || null;
});

//TWITTER DATAPOINTS
const coinTwitterData = computed(() => {
  if (props.widgetAccessLevel == 'restricted') {
    return {
      tweet_volume: 96237,
      daily_sentiment: 88,
      relative_tweet_volume: 0.4545,
      hourly_sentiment: 76
    };
  } else {
    return currentCoin.value;
  }
});
const twitterDatapoints = computed(() => {
  return [
    {
      primary: 'tweet_volume',
      primary_label: 'Tweets',
      secondary: 'daily_sentiment',
      secondary_label: 'Daily',
      formatter: useNumberFormatter,
      colors: getBarColors(currentCoin.value.daily_sentiment)
    },
    {
      primary: 'relative_tweet_volume',
      primary_label: 'Tweet Vol',
      secondary: 'hourly_sentiment',
      secondary_label: 'Hourly',
      formatter: 'percent',
      colors: getBarColors(currentCoin.value.hourly_sentiment)
    }
  ];
});

function getBarColors(value) {
  if (!value) {
    return { label: '', text: '', outline: '', fill: '', hover: '' };
  }
  if (value <= 40) {
    return {
      label: 'Very Negative',
      text: 'text-red-500',
      outline: 'from-red-500',
      fill: 'from-red-900/30',
      hover: 'group-hover:from-red-900/50'
    };
  } else if (value >= 60) {
    return {
      label: 'Very Positive',
      text: 'text-green-500',
      outline: 'from-green-500',
      fill: 'from-green-900/30',
      hover: 'group-hover:from-green-900/50'
    };
  } else {
    return {
      label: 'Neutral',
      text: 'text-gray-500',
      outline: 'from-gray-500',
      fill: 'from-gray-900/30',
      hover: 'group-hover:from-gray-900/50'
    };
  }
}
function getBarHeight(value) {
  const maxBarHeight = 128;
  const margin = maxBarHeight * 0.2;
  const percentage = value / 100;
  return maxBarHeight * percentage + margin;
}

//OTHER DATAPOINTS
const datapoints = computed(() => {
  let defaultDatapoints = [
    {
      id: 'trading_volume',
      label: 'Trading Volume',
      formatter: useNumberFormatter
    },
    {
      id: 'market_cap',
      label: 'Market Cap',
      formatter: useBigNumberFormatter
    },
    {
      id: 'tvl',
      label: 'TVL',
      formatter: useBigNumberFormatter
    },
    {
      id: 'all_time_high_price',
      label: 'ATH',
      formatter: usePriceFormatter
    },
    {
      id: 'all_time_low_price',
      label: 'ATL',
      formatter: usePriceFormatter
    },
    {
      id: 'max_supply',
      label: 'Max Supply',
      formatter: useNumberFormatter
    },
    {
      id: 'circulating_supply',
      label: 'Circulating Supply',
      formatter: useNumberFormatter
    }
  ];
  if (props.widgetAccessLevel == 'full') {
    defaultDatapoints.push(
      {
        id: 'relative_trade_volume',
        label: 'Rel. Trading Volume',
        formatter: 'percent'
      },
      {
        id: 'change_7d',
        label: '7D Change TVL',
        formatter: 'percent'
      },
      {
        id: '1h_exchange_flow_supply_pct',
        label: '1H Exchange Flow',
        formatter: 'percent'
      }
    );
  }
  return defaultDatapoints;
});

//CHART
const chartError = ref(false);
const chart = ref(null);
const chartData = ref([]);
const chartConfig = computed(() => {
  const priceHistory = chartData.value.map(x => parseFloat(x.price));
  return {
    chart: {
      type: 'area',
      events: {
        load: adjustGradient,
        redraw: adjustGradient
      }
    },
    xAxis: {
      visible: false
    },
    yAxis: {
      min: min(priceHistory),
      max: max(priceHistory),
      opposite: true
    },
    series: []
  };
});
const chartPayload = computed(() => {
  const timeframe = timeIntervals.find(x => x.id == selectedInterval.value);
  let startFrom = moment().utc().subtract(1, 'day');
  switch (timeframe.id) {
    case 1:
      startFrom = moment().utc().subtract(30, 'days');
      break;
    case 2:
      startFrom = moment().utc().subtract(1, 'year');
      break;
    case 3:
      startFrom = moment().utc().subtract(5, 'years');
      break;
    case 4:
      startFrom = moment().utc().startOf('year');
      break;
  }
  return {
    load_from_cache: selectedInterval.value == 0,
    coin_uids: selectedInterval.value == 0 ? [currentCoin.value?.ticker.toLowerCase()] : [currentCoin.value?.coin_uid],
    frequency: timeframe.interval,
    items: 'price',
    start_datetime: startFrom,
    end_datetime: moment.utc()
  };
});
const chartSeries = computed(() => {
  return chartData.value.map(x => {
    return [new Date(x.datetime).getTime(), parseFloat(x.price)];
  });
});
function plotChart() {
  nextTick(() => {
    const chartColor = getChartGradientColors(currentCoin.value?.price_return_24_hours || -1);
    chartConfig.value.series = [
      {
        name: 'Price',
        data: chartSeries.value,
        color: chartColor.color,
        fillColor: chartColor.gradient,
        lineWidth: 1.3
      }
    ];
    chart.value = Highcharts.chart('plot', chartConfig.value);
  });
}
function updateChartSeries() {
  if (chart.value) {
    const timeframe = timeIntervals.find(x => x.id == selectedInterval.value);
    const now = new Date().getTime();
    let roundedTime = new Date(moment().utc().startOf('day')).getTime();
    if (timeframe.id == 0) {
      roundedTime = now - (now % 300); //rounded to 5m
    }
    if (timeframe.id == 1) {
      roundedTime = now - (now % 3600); //rounded to hour
    }
    let updatedSeries = chartSeries.value;
    let latestDatapoint = chartSeries.value[chartSeries.value.length - 1];
    if (latestDatapoint && latestDatapoint[0] != roundedTime) {
      updatedSeries.push([roundedTime, currentCoin.value.price]);
    } else {
      updatedSeries[updatedSeries.length - 1] = [roundedTime, currentCoin.value.price];
    }
    chart.value.series[0].setData(updatedSeries);
  }
}
function adjustGradient() {
  document.getElementsByTagName('linearGradient')[0].setAttributeNS(null, 'y2', this.plotHeight);
}
function getChartGradientColors(value) {
  if (value <= 0) {
    return {
      color: colors.red[500],
      gradient: {
        linearGradient: [0, 0, 0, 0],
        stops: [
          [0, 'rgba(127, 29, 29, 1)'],
          [0.3, 'rgba(127, 29, 29, 0.6)'],
          [0.6, 'rgba(127, 29, 29, 0.1)'],
          [1, 'rgba(22, 24, 29, 0)']
        ]
      }
    };
  } else {
    return {
      color: colors.green[500],
      gradient: {
        linearGradient: [0, 0, 0, 0],
        stops: [
          [0, 'rgba(20, 83, 45, 1)'],
          [0.3, 'rgba(20, 83, 45, 0.6)'],
          [0.6, 'rgba(20, 83, 45, 0.1)'],
          [1, 'rgba(22, 24, 29, 0)']
        ]
      }
    };
  }
}
async function loadCoinHistory() {
  if (currentCoin.value?.ticker) {
    try {
      if (chart.value) {
        chart.value.showLoading();
      }
      chartError.value = false;
      let response = await $http.post('/integrations/coins/historical_grouped', chartPayload.value);
      chartData.value = response.data.data;
      coinLoading.value = false;
      plotChart();
    } catch {
      chartError.value = true;
      coinLoading.value = false;
      //do nothing
    }
  }
}
</script>
