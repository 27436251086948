<template>
  <div class="h-full" :id="id">
    <NexoWidgetsBase :widget="widget" :base-theme="baseTheme" :loading="loading">
      <template #body>
        <NexoButton
          v-if="showScrollToTop"
          @press="scrollToTop(scroller)"
          size="sm"
          class="btn-gototop bg-skin-btn-primary-accent absolute bottom-12 right-4 z-30"
        >
          <IconArrowUp />
        </NexoButton>
        <VirtualList
          v-if="news.length"
          class="my-3 px-6"
          ref="scroller"
          :id="`news-widget-${Math.random()}`"
          :style="`height: calc(100% - ${height}px); overflow-y: auto`"
          :data-key="'link_hash'"
          :data-sources="news"
          :data-component="component"
          :extra-props="{
            'coin-news': true,
            'widget-width': width
          }"
          @tobottom="getOlderNews(newsParams)"
        >
        </VirtualList>
        <div v-else-if="!loading" :style="`height: calc(100% - ${height}px); overflow-y: auto`">
          <NexoNoDataError />
        </div>
      </template>
    </NexoWidgetsBase>
  </div>
</template>

<script setup>
import { useStore } from 'vuex';
import VirtualList from 'vue3-virtual-scroll-list';
import useNewsMixin from '@/composeables/integrations/nexo/news';
import NexoSimpleNewsFeedItem from '../NexoSimpleNewsFeedItem.vue';
import { onMounted, onBeforeMount, onBeforeUnmount, computed, watch, ref, inject } from 'vue';

const $http = inject('http');
const $store = useStore();

const {
  showScrollToTop,
  latestTimestamp,
  news,
  pollingInterval,
  endTimestamp,
  loading,
  scrollToTop,
  getOlderNews,
  getNewsFromCache
} = useNewsMixin();

const props = defineProps({
  widget: { type: Object, required: true },
  baseTheme: { type: Object },
  overrides: { type: Object },
  templateId: { type: String }
});

const component = ref(NexoSimpleNewsFeedItem);
const scroller = ref(null);
const id = ref(props.widget.uuid || Math.random());

//FETCH DATA
onMounted(async () => {
  getTags();
  getNewsFromCache(cacheNewsParams.value);
  pollingInterval.value = setInterval(() => {
    getNewsFromCache(cacheNewsParams.value);
  }, 60000);
});

onBeforeUnmount(() => {
  clearInterval(pollingInterval.value);
});

//NEWS TAGS
const newsTags = ref({});
async function getTags() {
  let response = await $http.get('/integrations/news_tags');
  newsTags.value = response.data;
}

//ALLOWED COLLECTIONS
const allowedCollections = ref(['Crypto Native Publications', 'Traditional Finance', 'Mainstream Media']);
const allowedCollectionsIds = computed(() => {
  return newsTags.value.collections?.filter(x => allowedCollections.value.includes(x.name)).map(x => x.id) || [];
});

//ALLOWED SOURCES
const allowedSources = ref(['Nexo Blog']);
const allowedSourcesIds = computed(() => {
  return newsTags.value.sources?.filter(x => allowedSources.value.includes(x.name)).map(x => x.id) || [];
});

//COINS
const coinTicker = ref(null);
const isNexoToken = ref(false);
onBeforeMount(() => {
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has('coin')) {
    coinTicker.value = urlParams.get('coin').toLowerCase();
    if (coinTicker.value == 'nexo') {
      isNexoToken.value = true;
    }
  }
});
const coin = computed(() => {
  return $store.getters.coins.find(x => x.ticker.toLowerCase() == coinTicker.value) || null;
});

//NEWS PARAMETERS
const newsParams = computed(() => {
  if (coin.value?.id) {
    if (isNexoToken.value) {
      return { sources: allowedSourcesIds.value };
    } else {
      return {
        coins: [coin.value.id],
        collections: allowedCollectionsIds.value
      };
    }
  }
  return null;
});
const cacheNewsParams = computed(() => {
  return {
    load_from_cache: true,
    cache_key: `nexo_${coinTicker.value}_news`
  };
});

//WIDGET WIDTH
const width = ref(0);
onMounted(async () => {
  updateWidth();
  const elm = document.getElementById(id.value);
  if (ResizeObserver) {
    new ResizeObserver(() => {
      updateWidth();
    }).observe(elm);
  }
});
function updateWidth() {
  const elm = document.getElementById(id.value);
  if (elm) {
    width.value = elm.clientWidth;
  }
}

//NEWS COMPONENT HEIGHT
const height = ref(0);
onMounted(() => {
  height.value = props.baseTheme && props.baseTheme.settings.hide_header ? 28 : 28;
});

//RESET NEWS
watch(props.widget.configuration, () => {
  resetAndFetchNews();
});
function resetAndFetchNews() {
  latestTimestamp.value = null;
  endTimestamp.value = null;
  loading.value = true;
  news.value = [];
  getNewsFromCache(cacheNewsParams.value);
}
</script>
