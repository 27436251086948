<template>
  <div class="h-full">
    <NexoWidgetsBase
      :widget="widget"
      :base-theme="baseTheme"
      :loading="chartLoading"
      :widget-access-level="widgetAccessLevel"
    >
      <template #body>
        <template v-if="!coinTicker || chartError">
          <NexoNoDataError />
        </template>
        <template v-else>
          <div class="h-full w-full py-4 px-6">
            <div class="flex justify-end px-4 pb-2">
              <NexoTags :tags="timeIntervals" v-model="selectedInterval" />
            </div>
            <Chart style="height: calc(100% - 30px)" class="w-full" :options="chartOptions" />
          </div>
        </template>
      </template>
    </NexoWidgetsBase>
  </div>
</template>
<script setup>
import { useStore } from 'vuex';
import { Chart } from 'highcharts-vue';
import { ref, onBeforeMount, watch, inject, computed } from 'vue';
import { useBigNumberFormatter } from '@/composeables/integrations/nexo/helpers';
import moment from 'moment';
import colors from 'tailwindcss/colors';

const $http = inject('http');
const $store = useStore();
const props = defineProps({
  widget: { type: Object, required: true },
  baseTheme: { type: Object },
  overrides: { type: Object },
  templateId: { type: String },
  widgetAccessLevel: { type: String }
});

//INTERVALS
const selectedInterval = ref(2);
const timeIntervals = [
  { id: 0, days: 7, label: '7D' },
  { id: 1, days: 30, label: '30D' },
  { id: 2, days: 90, label: '3M' },
  { id: 3, days: 180, label: '6M' },
  { id: 4, days: 365, label: '1Y' },
  { id: 5, days: 0, label: 'ALL' }
];

//COIN
const coinTicker = ref(null);
onBeforeMount(() => {
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has('coin')) {
    coinTicker.value = urlParams.get('coin').toLowerCase();
    loadChartData();
  } else {
    chartLoading.value = false;
  }
});

//CHART
const chartLoading = ref(true);
const chartError = ref(false);
const chartOptions = computed(() => {
  return {
    chart: {
      type: 'area',
      events: {
        load: adjustGradient,
        redraw: adjustGradient
      }
    },
    xAxis: {
      visible: true
    },
    yAxis: {
      opposite: false
    },
    tooltip: {
      pointFormatter: function () {
        return '$' + useBigNumberFormatter(this.y);
      }
    },
    series: [
      {
        name: 'TVL',
        data: chartSeries.value,
        color: colors.blue[500],
        fillColor: {
          linearGradient: [0, 0, 0, 0],
          stops: [
            [0, 'rgba(30, 58, 138, 0.6)'],
            [0.3, 'rgba(30, 58, 138, 0.3)'],
            [0.6, 'rgba(30, 58, 138, 0.1)'],
            [1, 'rgba(22, 24, 29, 0)']
          ]
        }
      }
    ]
  };
});
const chartData = ref([]);
const chartSeries = computed(() => {
  const interval = timeIntervals.find(x => x.id == selectedInterval.value);
  let filtered = chartData.value;
  if (interval.days != 0) {
    filtered = filtered.slice(-1 * interval.days);
  }
  return filtered.map(x => {
    return [x.date * 1000, x.totalLiquidityUSD];
  });
});
async function loadChartData() {
  if (coinTicker.value) {
    try {
      chartError.value = false;
      let response = await $http.get('/integrations/tvl_historical', {
        params: { ticker: coinTicker.value }
      });
      if (!response.data) {
        chartError.value = true;
      }
      chartData.value = response.data;
    } catch {
      chartError.value = true;
    } finally {
      chartLoading.value = false;
    }
  } else {
    chartLoading.value = false;
  }
}

function adjustGradient() {
  document.getElementsByTagName('linearGradient')[0].setAttributeNS(null, 'y2', this.plotHeight);
}
</script>
